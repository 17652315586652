import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from "react";
import HourglassGlobals, { HGContext } from "../helpers/globals";
import { useTranslation } from "react-i18next";
import logo from "../assets/hourglass-logo-brown.svg";
import {
  arrayBufferToBase64,
  base64ToArrayBuffer,
  base64ToUint8Array,
  e2ePasswordStrong,
  EncryptionError,
  keyFingerprint,
  uint8ArrayToBase64,
  unwrapKey,
  wrapCryptoKey,
  WrappedKey,
} from "../helpers/e2e";
import { E2EKey } from "../types/e2e";
import { e2eApi } from "../api/e2e";
import { UserAPI } from "../api/user";
import { ExclamationTriangleFill, LockFill } from "react-bootstrap-icons";
import { Cong } from "../types/cong";

//show UI to get the master password
//decrypt the wrapped key using it, and set the master key into the context
export function E2EMasterPassword() {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const pwInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    pwInput.current?.focus();
  });

  const updatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    setErrorMessage("");
  };

  const submitMasterPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const e2eKey = ctx.globals.cong!.e2ekey;
    if (!e2eKey) return;

    try {
      const wrappedKey: WrappedKey = {
        key: base64ToArrayBuffer(e2eKey.wrappedKey),
        salt: base64ToUint8Array(e2eKey.salt),
      };

      const masterKey = await unwrapKey(wrappedKey, password);
      // set the key, which enables e2e when UserAPI.userFromResponse checks for it
      HourglassGlobals.e2eKey = masterKey;
      const decryptedAuthUser = await UserAPI.userFromResponse(ctx.globals.authUser);
      ctx.setGlobals({ ...ctx.globals, e2eKey: masterKey, authUser: decryptedAuthUser });
    } catch (err: any) {
      console.error("error unwrapping key", err);
      setErrorMessage(t("userauth.feedback.password.current"));
    }
  };

  return (
    <Container fluid="md">
      <Form noValidate onSubmit={submitMasterPassword}>
        <Row className="justify-content-center">
          <Col xs="auto">
            <img src={logo} className="app-logo-lg mb-3" alt="logo" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={3} md={4} sm={5}>
            <Form.Group controlId="password" className="text-center">
              <Form.Label>
                <b>{t("e2e.congregation-master-password")}</b>
              </Form.Label>
              <Form.Control
                required
                ref={pwInput}
                type="password"
                value={password}
                onChange={updatePassword}
                isInvalid={!!errorMessage}
                style={{ display: "inline" }}
              />
              <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button variant="primary" type="submit" className="mt-2">
              {t("popup.error.button.ok")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export function E2EChangePasswordModal(props: { show: boolean; setShow: (show: boolean) => void }) {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [masterPassword, setMasterPassword] = useState("");
  const [masterPasswordConfirm, setMasterPasswordConfirm] = useState("");
  const masterPasswordRef = useRef<HTMLInputElement>(null);
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const [saveError, setSaveError] = useState("");

  const changePassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    const e2eKey = ctx.globals.cong!.e2ekey;
    if (!e2eKey || !e2eKey.id) {
      console.error("no cong e2ekey found in context!");
      return;
    }

    if (!ctx.globals.e2eKey) {
      console.error("no e2e master key found in context!");
      return;
    }

    if (!e2ePasswordStrong(masterPassword)) {
      masterPasswordRef.current?.setCustomValidity(t("password-error.insecure"));
    } else {
      masterPasswordRef.current?.setCustomValidity("");
    }

    const wrappedKey: WrappedKey = {
      key: base64ToArrayBuffer(e2eKey.wrappedKey),
      salt: base64ToUint8Array(e2eKey.salt),
    };
    //verify that the current password is correct
    try {
      await unwrapKey(wrappedKey, currentPassword);
      currentPasswordRef.current?.setCustomValidity("");
    } catch {
      currentPasswordRef.current?.setCustomValidity(t("userauth.feedback.password.current"));
    }

    if (!form) {
      console.error("form is invalid for some reason?", form);
      return;
    }
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    const masterKey = ctx.globals.e2eKey;
    //construct a new e2ekey, using the same master key and wrapping it with the new password, and save it
    try {
      const newWrappedKey = await wrapCryptoKey(masterKey, masterPassword);
      const newE2EKey: E2EKey = {
        id: e2eKey.id,
        wrappedKey: arrayBufferToBase64(newWrappedKey.key),
        salt: uint8ArrayToBase64(newWrappedKey.salt),
        fingerprint: arrayBufferToBase64(await keyFingerprint(masterKey)),
      };
      const newKey = await e2eApi.save(newE2EKey);
      const newCong: Cong = { ...ctx.globals.cong!, e2ekey: newKey };
      ctx.setGlobals({ ...ctx.globals, cong: newCong });
      handleClose();
    } catch (err: any) {
      if (err instanceof EncryptionError) {
        setSaveError(t("e2e.error.failed-to-create"));
      } else {
        setSaveError(t("e2e.error.failed-to-save"));
      }
    }
  };

  const handleClose = () => {
    setCurrentPassword("");
    setMasterPassword("");
    setMasterPasswordConfirm("");
    props.setShow(false);
  };

  const changeCurrentPassword = async (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.currentTarget.value);
    setSaveError("");
    currentPasswordRef.current?.setCustomValidity("");
  };

  const changeMasterPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setMasterPassword(e.currentTarget.value);
    setSaveError("");
    masterPasswordRef.current?.setCustomValidity("");
  };

  const changeMasterPasswordConfirm = (e: ChangeEvent<HTMLInputElement>) => {
    setMasterPasswordConfirm(e.currentTarget.value);
    setSaveError("");
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("nav.e2e")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={changePassword}>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-3">
            <ExclamationTriangleFill className="warning-color" size="3em" />
          </div>
          <p>
            <b>{t("e2e.enable.password-share")}</b>
          </p>
          <Form.Group>
            <Form.Label>{t("userauth.form.label.current-password")}</Form.Label>
            <Form.Control
              ref={currentPasswordRef}
              required
              type="password"
              value={currentPassword}
              onChange={changeCurrentPassword}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label>{t("e2e.congregation-master-password")}</Form.Label>
            <Form.Control
              ref={masterPasswordRef}
              required
              type="password"
              value={masterPassword}
              onChange={changeMasterPassword}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label>{t("new-password.tip.new-password-again")}</Form.Label>
            <Form.Control
              required
              type="password"
              value={masterPasswordConfirm}
              onChange={changeMasterPasswordConfirm}
            />
          </Form.Group>
          {!!saveError && <p className="text-danger">{saveError}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="primary"
            disabled={!currentPassword || !masterPassword || masterPassword !== masterPasswordConfirm}
          >
            <LockFill /> {t("e2e.change.master-password")}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {t("general.cancel")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
