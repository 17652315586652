import { Cong } from "../types/cong";
import { Congregation } from "../types/scheduling/weekend";
import HourglassGlobals from "./globals";

export function CongregationToCong(input: Cong | Congregation): Cong {
  if (Object.hasOwn(input, "mmdow")) {
    return input as Cong;
  }

  const c = input as unknown as Congregation;

  return {
    id: c.id,
    guid: c.guid,
    name: c.name,
    wmdow: c.wm_dow,
    wmtime: c.wm_time,
    mmdow: c.mm_dow,
    mmtime: c.mm_time,
    wmdow_future: c.future_wm_dow,
    wmtime_future: c.future_wm_time,
    mmdow_future: c.future_mm_dow,
    mmtime_future: c.future_mm_time,
    future_start_date: c.future_start_date,
    timezone: { id: 0, name: c.timezone_name, offset: c.timezone_offset },
  } as Cong;
}

// getMyCongAndGroupIds returns a set of all congregation and language groups associated with the logged-in user.
export function getMyCongAndGroupIds(): Set<number> {
  const langGroupIds: number[] = HourglassGlobals.language_groups.map((lg) => lg.id);
  return new Set<number>([...langGroupIds, HourglassGlobals.cong!.id]);
}
