import dayjs from "dayjs";
import {
  AlignmentType,
  BorderStyle,
  convertInchesToTwip,
  Document,
  Footer,
  Header,
  HeadingLevel,
  HeightRule,
  IParagraphOptions,
  IRunOptions,
  ITableCellOptions,
  ITableRowOptions,
  Packer,
  PageBreak,
  PageOrientation,
  Paragraph,
  ShadingType,
  SimpleField,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import saveAs from "file-saver";
import { t } from "i18next";
import { avAttendantApi } from "../../api/avAttendant";
import { midweekApi } from "../../api/midweek";
import { otherScheduleApi } from "../../api/otherSchedule";
import { weekendApi } from "../../api/weekend";
import {
  mmDate as getMMDate,
  wmDate as getWmDate,
  ISODateFormat,
  localizedTime,
  Month,
  parseTime,
  stringToLongLocaleDate,
  timeFor,
  weekOfString,
} from "../../helpers/dateHelpers";
import { PAGE_MARGIN, pageDimensionsTwip, TABLE_NO_BORDERS } from "../../helpers/docx";
import { nameOfUser } from "../../helpers/user";
import { chunkColumns, stringCompare } from "../../helpers/util";
import { Cong } from "../../types/cong";
import { ISODateString } from "../../types/date";
import { FSGroup } from "../../types/fsgroup";
import { AVAttendantAssignment, AVAttendantAssignmentType } from "../../types/scheduling/avAttendant";
import { Events, EventTypes, ScheduledEvent } from "../../types/scheduling/events";
import { MidweekMeetingPartType, MMs } from "../../types/scheduling/midweek";
import { OtherSchedule, OtherScheduleKind } from "../../types/scheduling/other";
import { CongSettings } from "../../types/scheduling/settings";
import { TalkMod, WMSchedule } from "../../types/scheduling/weekend";
import User from "../../types/user";
import { avaLabel } from "./av_attendant/week";
import { songNum } from "./mm/mm_pdf";
import { cleaningLabel } from "./other/common";
import { CongName } from "./common";
import { congPaperSize } from "../../helpers/paper";

const maxHalls = 3;

const Color = {
  white: "#ffffff",
  black: "#373433",
  gray: "#808080",
  blue: "#8EAADB",
  lightGray: "#F3F3F3",
  hg: "#9E8B5E",
  tgw: "#286876",
  fm: "#AB7800",
  lac: "#942823",
  pt: "#2f4870",
  wt: "#4d6501",
  av: "#233983",
  att: "#C30076",
  other: "#26C300",
};

const ParaStyle = {
  time: "time",
  ava: "ava",
  hall: "halll",
  primary: "primary",
  secondary: "secondary",
  name: "name",
  footer: "footer",
};

const RunStyles = {
  avaSm: { size: 7 * 2, color: Color.gray },
  sm: { size: 7 * 2, color: Color.gray, bold: false },
  secondary: { color: Color.gray },
};

const avaCellAttr = {
  columnSpan: 2,
  verticalAlign: VerticalAlign.TOP,
  //borders: { bottom: { style: BorderStyle.SINGLE, size: 8, color: Color.hg } },
  shading: { color: Color.lightGray, type: ShadingType.SOLID },
};

const headCellAttrGen = (color: string) => ({
  columnSpan: 8,
  borders: { bottom: { style: BorderStyle.SINGLE, size: 2 * 8, color } },
});

const AVAssignments = [
  AVAttendantAssignmentType.Console,
  AVAttendantAssignmentType.Mics,
  AVAttendantAssignmentType.Stage,
  AVAttendantAssignmentType.Video,
];

const AttendantAssignments = [
  AVAttendantAssignmentType.Attendant,
  AVAttendantAssignmentType.SecurityAttendant,
  AVAttendantAssignmentType.ZoomAttendant,
];

const FMAssistedParts = new Set([
  MidweekMeetingPartType.InitialCall,
  MidweekMeetingPartType.RV,
  MidweekMeetingPartType.StudentBibleStudy,
]);

type CombinedCellData = {
  cellAttr: Partial<ITableCellOptions>;
  paraAttr: Partial<IParagraphOptions>;
  runs: IRunOptions[];
};

type CombinedData = {
  rowAttr?: Partial<ITableRowOptions>;
  cells: CombinedCellData[];
};

export const createCombinedDOCX = async (
  from: ISODateString,
  to: ISODateString,
  cong: Cong,
  settings: CongSettings,
  userMap: Map<number, User>,
  fsGroups: FSGroup[],
  events: ScheduledEvent[],
  lgroup: number,
) => {
  const fromMonday = weekOfString(from);
  const toSunday = weekOfString(to, 7);

  // get all the data concurrently
  const [mmData, wmData, avData, cleanData] = await Promise.all([
    midweekApi.getMeetingsInfo(fromMonday, toSunday, lgroup, false),
    weekendApi.getSchedules(fromMonday, toSunday, lgroup),
    avAttendantApi.getAssignmentsRange(fromMonday, toSunday, lgroup),
    otherScheduleApi.get(OtherScheduleKind.Cleaning, fromMonday, toSunday),
  ]);

  await generateCombinedDoc(cong, settings, userMap, fsGroups, mmData, wmData, avData, cleanData, events, from);
};

export async function generateCombinedDoc(
  cong: Cong,
  settings: CongSettings,
  userMap: Map<number, User>,
  fsGroups: FSGroup[],
  mmData: MMs,
  wmData: WMSchedule[],
  avData: AVAttendantAssignment[],
  cleanData: OtherSchedule[],
  events: ScheduledEvent[],
  from: ISODateString,
) {
  if (!mmData.meetings) return;

  const pages = mmData.meetings.map((mm): CombinedData[] => {
    const mmS = mmData.schedules.find((m) => m.date === mm.date);
    const wmS = wmData.find((m) => m.date === mm.date);
    const event = events.find((e) => e.week === mmS?.date);
    const isCOVisit = event?.event === Events.co;
    const coVisitLabel = ` — ${t("schedules.events.co-visit")}`;
    const isAssembly = event?.event === Events.ca;
    const isConvOrAssembly = [Events.rc, Events.ca].includes(event?.event ?? Events.custom);
    const isMMemorial = event?.event === Events.mmem;
    const isWMemorial = event?.event === Events.wmem;
    const convAssemblyLabel = t(EventTypes[event?.event ?? Events.custom]);
    const hallsQty = isCOVisit ? 1 : settings.school_size;
    const weekRange = { from: dayjs(mm.date), to: dayjs(mm.date).add(7, "day") };

    const mmDate = isMMemorial
      ? dayjs(event.date)
      : dayjs(settings.mm_print_date === "dayof" ? getMMDate(mm.date, cong, events) : mm.date);
    const wmDate =
      isAssembly || isWMemorial
        ? dayjs(event.date)
        : dayjs(settings.wm_print_date === "dayof" ? getWmDate(mm.date, cong, events) : mm.date);

    const mmTitle = isConvOrAssembly || isMMemorial ? "" : t("conganalysis.attendance.midweek");
    const wmTitle = isConvOrAssembly || isWMemorial ? "" : t("conganalysis.attendance.weekend");
    const withAuxHall = (hallIdx: number, text: string) => (hallIdx < hallsQty ? text : "");
    const name = (id?: number) => userMap.get(id ?? 0)?.displayName ?? "";
    const closingPrayer = (id?: number): string => {
      if (id) return name(id);
      if (wmS?.speaker.id) name(wmS?.speaker.id);
      if (wmS?.talk_mod === TalkMod.CO || isCOVisit) return wmS?.speaker.firstname || t("general.circuit-overseer");
      return "";
    };
    const groupOrName = (id?: number) => fsGroups.find((g) => g.overseer_id === id)?.name ?? name(id);
    const filterAVA = (
      avData: AVAttendantAssignment[],
      assignments: AVAttendantAssignmentType[],
      date: string,
      settings: CongSettings,
    ) => {
      return avData
        .filter((a) => assignments.includes(a.type))
        .filter((ava) => ava.date === date && ava.assignee)
        .map((a) => {
          const label = avaLabel(settings, a.type, a.slot);
          return {
            name: name(a.assignee),
            label: label.length ? label : avheading(a.type),
          };
        })
        .sort((a, b) => stringCompare(a.label, b.label));
    };

    const mmTime = parseTime(timeFor(mm.date, cong, "midweek"));
    let globalTime = mmDate.add(mmTime.hour, "hour").add(mmTime.minute, "minute");
    const wmTime = parseTime(timeFor(mm.date, cong, "weekend"));
    let globalWMTime = wmDate.add(wmTime.hour, "hour").add(wmTime.minute, "minute");

    //MM AVA & Other
    const mmAv = filterAVA(avData, AVAssignments, mm.date, settings);
    const mmAtt = filterAVA(avData, AttendantAssignments, mm.date, settings);

    const mmClean = cleanData
      .filter((c) => c.date === mmDate.format(ISODateFormat) && settings.mm_print_date === "dayof")
      .map((c) => {
        return {
          name: groupOrName(c.assigneeUserId ?? 0),
          label: cleaningLabel(settings, c.slot) ?? t("schedules.cleaning.title"),
        };
      });

    //WM AVA & Other
    const wmAvaDate = wmDate.day()
      ? wmDate.add(7 - wmDate.day(), "day").format(ISODateFormat)
      : wmDate.format(ISODateFormat); //wm ava date is always sunday.
    const wmAv = filterAVA(avData, AVAssignments, wmAvaDate, settings);
    const wmAtt = filterAVA(avData, AttendantAssignments, wmAvaDate, settings);

    const wmClean = cleanData
      .filter((c) => c.date === wmDate.format(ISODateFormat) && settings.wm_print_date === "dayof")
      .map((c) => {
        return {
          name: groupOrName(c.assigneeUserId ?? 0),
          label: cleaningLabel(settings, c.slot) ?? t("schedules.cleaning.title"),
        };
      });
    if (wmS?.host) {
      wmClean.push({
        name: settings.hospitality_by_group ? groupOrName(wmS.host) : name(wmS.host),
        label: t("schedules.privileges.hospitality"),
      });
    }

    //Other cleaning assignments
    const otherClean = cleanData
      .filter(
        (c) =>
          (dayjs(c.date).isSame(weekRange.from) || dayjs(c.date).isAfter(weekRange.from)) &&
          dayjs(c.date).isBefore(weekRange.to),
      )
      .filter(
        (c) =>
          (c.date !== mmDate.format(ISODateFormat) || settings.mm_print_date !== "dayof") &&
          (c.date !== wmDate.format(ISODateFormat) || settings.wm_print_date !== "dayof"),
      )
      .map((c) => {
        return {
          name: groupOrName(c.assigneeUserId ?? 0),
          label: cleaningLabel(settings, c.slot) ?? t("schedules.cleaning.title"),
          date: stringToLongLocaleDate(c.date, "full"),
        };
      })
      .reduce(
        (hash, { date: value, ...rest }) => ({ ...hash, [value]: [...(hash[value] || []), rest] }),
        <Record<string, { name: string; label: string }[]>>{},
      );

    const getTimeAndAdd = (partDuration: number) => {
      const t = globalTime.format("HH:mm:ss");
      const result = localizedTime(t, cong.locale.code).replaceAll(" ", "\xa0");
      globalTime = globalTime.add(partDuration, "minute");
      return result;
    };
    const getWMTimeAndAdd = (partDuration: number) => {
      const t = globalWMTime.format("HH:mm");
      const result = localizedTime(t, cong.locale.code).replaceAll(" ", "\xa0");
      globalWMTime = globalWMTime.add(partDuration, "minute");
      return result;
    };
    const parsePartTime = (timeStr: string) => parseInt(timeStr.replace(/[^0-9.]/g, ""));
    const fmParts = mm?.fm ?? [];

    return [
      invisibleRow(),

      /**
       * MIDWEEK MEETING
       */
      {
        cells: [
          {
            cellAttr: { columnSpan: 3, shading: { color: Color.hg, type: ShadingType.SOLID } },
            paraAttr: { heading: HeadingLevel.HEADING_1 },
            runs: [{ text: stringToLongLocaleDate(mmDate.format(ISODateFormat), "full"), bold: true }],
          },
          {
            cellAttr: { columnSpan: 5, shading: { color: Color.black, type: ShadingType.SOLID } },
            paraAttr: { heading: HeadingLevel.HEADING_1 },
            runs: [{ text: mmTitle }, ...(isCOVisit ? [{ text: coVisitLabel, ...RunStyles.secondary }] : [])],
          },
        ],
      },
      ...(isConvOrAssembly || isMMemorial
        ? noMeeting(convAssemblyLabel, event?.custom_body ?? "", isMMemorial)
        : [
            //Halls
            {
              cells: [
                { cellAttr: { columnSpan: 3 }, paraAttr: {}, runs: [] },
                {
                  cellAttr: { columnSpan: 2 },
                  paraAttr: { style: ParaStyle.hall },
                  runs: [{ text: withAuxHall(2, t("schedules.midweek.auxiliary-classroom-2")) }],
                },
                {
                  cellAttr: { columnSpan: 2 },
                  paraAttr: { style: ParaStyle.hall },
                  runs: [{ text: withAuxHall(1, t("schedules.midweek.auxiliary-classroom")) }],
                },
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.hall },
                  runs: [{ text: t("schedules.midweek.main-hall") }],
                },
              ],
            },
            // Opening Prayer
            {
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getTimeAndAdd(5), color: Color.tgw }],
                },
                {
                  cellAttr: { columnSpan: 6 },
                  paraAttr: { style: ParaStyle.secondary },
                  runs: [{ text: songNum(mm.song1?.number) }, { text: " — " }, { text: mm.song1?.title }],
                },
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [{ text: name(mmS?.openprayer) }, { text: ` (${t("schedules.prayer")})`, ...RunStyles.sm }],
                },
              ],
            },
            // Opening Comments
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getTimeAndAdd(1), color: Color.tgw }],
                },
                {
                  cellAttr: { columnSpan: 2 },
                  paraAttr: { style: ParaStyle.primary },
                  runs: [{ text: t("schedules.midweek.opening-comments") }],
                },
                {
                  cellAttr: { columnSpan: 2 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [
                    { text: withAuxHall(2, name(mmS?.chairman3)) },
                    { text: withAuxHall(2, t("schedules.midweek.aux-chairman-2")), ...RunStyles.sm, break: 1 },
                  ],
                },
                {
                  cellAttr: { columnSpan: 2 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [
                    { text: withAuxHall(1, name(mmS?.chairman2)) },
                    { text: withAuxHall(1, t("schedules.midweek.aux-chairman-1")), ...RunStyles.sm, break: 1 },
                  ],
                },
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [{ text: name(mmS?.chairman) }, { text: t("schedules.chairman"), ...RunStyles.sm, break: 1 }],
                },
              ],
            },
            // Treasures Title
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: headCellAttrGen(Color.tgw),
                  paraAttr: { heading: HeadingLevel.HEADING_2 },
                  runs: [
                    { text: t("schedules.midweek.treasures"), color: Color.tgw },
                    { text: ` (${mm.weekly_br})`, color: Color.tgw },
                  ],
                },
              ],
            },

            // Treasures Parts
            ...mm.tgw.map((part) => {
              const isStudent = !!part.counsel_point;
              const duration = parsePartTime(part.time);
              const assignees = mmS?.tgw.filter((s) => s.part === part.id).sort((a, b) => a.classroom - b.classroom);
              return {
                rowAttr: {},
                cells: [
                  {
                    cellAttr: { columnSpan: 1 },
                    paraAttr: { style: ParaStyle.time },
                    runs: [{ text: getTimeAndAdd(duration + (isStudent ? 1 : 0)), color: Color.tgw }],
                  },
                  {
                    cellAttr: { columnSpan: isStudent ? 2 : 6 },
                    paraAttr: { style: ParaStyle.primary },
                    runs: [{ text: part.title }, { text: ` (${part.time})`, ...RunStyles.sm }],
                  },
                  ...(isStudent
                    ? [...Array(maxHalls)]
                        .map((_, i) => {
                          const a = assignees?.find((a) => a.classroom === i);
                          return {
                            cellAttr: { columnSpan: i ? 2 : 1 },
                            paraAttr: { style: ParaStyle.name },
                            runs: [{ text: withAuxHall(i, name(a?.assignee)) }],
                          };
                        })
                        .reverse()
                    : [
                        {
                          cellAttr: { columnSpan: 1 },
                          paraAttr: { style: ParaStyle.name },
                          runs: [{ text: name(assignees?.at(0)?.assignee) }],
                        },
                      ]),
                ],
              };
            }),
            // Field Ministry Title
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: headCellAttrGen(Color.fm),
                  paraAttr: { heading: HeadingLevel.HEADING_2 },
                  runs: [{ text: t("schedules.midweek.fm"), color: Color.fm }],
                },
              ],
            },
            // Field Ministry Parts
            ...fmParts.map((part) => {
              const isAssisted = FMAssistedParts.has(part.type);
              const isStudent = !!part.counsel_point || !!part.counsel_point_txt || isAssisted;
              const duration = parsePartTime(part.time);
              const assignees = mmS?.fm.filter((s) => s.part === part.id).sort((a, b) => a.classroom - b.classroom);
              return {
                rowAttr: {},
                cells: [
                  {
                    cellAttr: { columnSpan: 1 },
                    paraAttr: { style: ParaStyle.time },
                    runs: [{ text: getTimeAndAdd(duration + (isStudent ? 1 : 0)), color: Color.fm }],
                  },
                  {
                    cellAttr: { columnSpan: isStudent ? 2 : 6 },
                    paraAttr: { style: ParaStyle.primary },
                    runs: [{ text: part.title }, { text: ` (${part.time})`, ...RunStyles.sm }],
                  },
                  ...(isStudent
                    ? [...Array(maxHalls)]
                        .map((_, i) => {
                          const a = assignees?.find((a) => a.classroom === i);
                          return {
                            cellAttr: { columnSpan: i ? 2 : 1 },
                            paraAttr: { style: ParaStyle.name },
                            runs: [
                              { text: withAuxHall(i, name(a?.assignee)) },
                              ...(isAssisted
                                ? [{ text: withAuxHall(i, name(a?.assistant)), ...RunStyles.sm, break: 1 }]
                                : []),
                            ],
                          };
                        })
                        .reverse()
                    : [
                        {
                          cellAttr: { columnSpan: 1 },
                          paraAttr: { style: ParaStyle.name },
                          runs: [
                            { text: name(assignees?.at(0)?.assignee) },
                            ...(isAssisted
                              ? [{ text: name(assignees?.at(0)?.assistant), ...RunStyles.sm, break: 1 }]
                              : []),
                          ],
                        },
                      ]),
                ],
              };
            }),
            // Living as Christians Title
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: headCellAttrGen(Color.lac),
                  paraAttr: { heading: HeadingLevel.HEADING_2 },
                  runs: [{ text: t("schedules.midweek.lac"), color: Color.lac }],
                },
              ],
            },
            // Living as Christians Song
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getTimeAndAdd(4), color: Color.lac }],
                },
                {
                  cellAttr: { columnSpan: 7 },
                  paraAttr: { style: ParaStyle.secondary },
                  runs: [{ text: songNum(mm.song2?.number) }, { text: " — " }, { text: mm.song2?.title }],
                },
              ],
            },
            // Living as Christians  Parts
            ...mm.lac.map((part) => {
              const duration = parsePartTime(part.time);
              const assignees = mmS?.lac.filter((s) => s.part === part.id);
              const isCBS = part.type === MidweekMeetingPartType.CBS;
              if (isCBS && isCOVisit) return invisibleRow();
              return {
                rowAttr: {},
                cells: [
                  {
                    cellAttr: { columnSpan: 1 },
                    paraAttr: { style: ParaStyle.time },
                    runs: [{ text: getTimeAndAdd(duration), color: Color.lac }],
                  },
                  {
                    cellAttr: { columnSpan: isCBS ? 5 : 6 },
                    paraAttr: { style: ParaStyle.primary },
                    runs: [{ text: part.title }, { text: ` (${part.time})`, ...RunStyles.sm }],
                  },
                  {
                    cellAttr: { columnSpan: isCBS ? 2 : 1 },
                    paraAttr: { style: ParaStyle.name },
                    runs: [
                      { text: name(assignees?.at(0)?.assignee) },
                      ...(isCBS ? [{ text: ` · ${name(mmS?.cbs_reader)}`, ...RunStyles.sm }] : []),
                    ],
                  },
                ],
              };
            }),
            // Concluding Comments
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getTimeAndAdd(3), color: Color.lac }],
                },
                {
                  cellAttr: { columnSpan: 6 },
                  paraAttr: { style: ParaStyle.primary },
                  runs: [
                    { text: t("schedules.midweek.concluding-comments") },
                    { text: ` (${t("schedules.duration-min", { count: 3 })})`, ...RunStyles.sm },
                  ],
                },
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [{ text: name(mmS?.chairman) }],
                },
              ],
            },
            // CO Visit Talk
            ...(isCOVisit
              ? [
                  {
                    rowAttr: {},
                    cells: [
                      {
                        cellAttr: { columnSpan: 1 },
                        paraAttr: { style: ParaStyle.time },
                        runs: [{ text: getTimeAndAdd(30), color: Color.lac }],
                      },
                      {
                        cellAttr: { columnSpan: 6 },
                        paraAttr: { style: ParaStyle.primary },
                        runs: [
                          { text: settings.circuit_overseer_mm_talk_title },
                          { text: ` (${t("schedules.duration-min", { count: 30 })})`, ...RunStyles.sm },
                        ],
                      },
                      {
                        cellAttr: { columnSpan: 1 },
                        paraAttr: { style: ParaStyle.name },
                        runs: [{ text: settings.circuit_overseer_name }],
                      },
                    ],
                  },
                ]
              : []),
            // Closing Prayer
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getTimeAndAdd(5), color: Color.lac }],
                },
                {
                  cellAttr: { columnSpan: 6 },
                  paraAttr: { style: ParaStyle.secondary },
                  runs: [{ text: songNum(mm.song3?.number) }, { text: " — " }, { text: mm.song3?.title }],
                },
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [{ text: name(mmS?.closeprayer) }, { text: ` (${t("schedules.prayer")})`, ...RunStyles.sm }],
                },
              ],
            },

            //MM AVA
            ...avaOtherRows(mmAv),
            ...avaOtherRows(mmAtt),
            ...avaOtherRows(mmClean),
          ]),
      // Template
      emptyRow(),
      /**
       * WEEKEND MEETING
       */
      //WM HEADING
      {
        cells: [
          {
            cellAttr: { columnSpan: 3, shading: { color: Color.hg, type: ShadingType.SOLID } },
            paraAttr: { heading: HeadingLevel.HEADING_1 },
            runs: [{ text: stringToLongLocaleDate(wmDate.format("YYYY-MM-DD"), "full"), bold: true }],
          },
          {
            cellAttr: { columnSpan: 5, shading: { color: Color.black, type: ShadingType.SOLID } },
            paraAttr: { heading: HeadingLevel.HEADING_1 },
            runs: [{ text: wmTitle }, ...(isCOVisit ? [{ text: coVisitLabel, ...RunStyles.secondary }] : [])],
          },
        ],
      },
      ...(isConvOrAssembly || isWMemorial
        ? noMeeting(convAssemblyLabel, event?.custom_body ?? "", isWMemorial)
        : [
            // Opening Prayer
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getWMTimeAndAdd(5), color: Color.pt }],
                },
                {
                  cellAttr: { columnSpan: 4 },
                  paraAttr: { style: ParaStyle.secondary },
                  runs: wmS?.opening_song?.number
                    ? [
                        { text: songNum(wmS?.opening_song?.number) },
                        { text: " — " },
                        { text: wmS?.opening_song?.title },
                      ]
                    : [{ text: songNum(0) }],
                },
                {
                  cellAttr: { columnSpan: 3 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [{ text: name(wmS?.wm_chairman) }, { text: ` (${t("schedules.chairman")})`, ...RunStyles.sm }],
                },
              ],
            },
            // Public Talk
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: headCellAttrGen(Color.pt),
                  paraAttr: { heading: HeadingLevel.HEADING_2 },
                  runs: [{ text: t("schedules.assignment.public-talk").replaceAll("%@", ""), color: Color.pt }],
                },
              ],
            },
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getWMTimeAndAdd(30), color: Color.pt }],
                },
                {
                  cellAttr: { columnSpan: 5 },
                  paraAttr: { style: ParaStyle.primary },
                  runs: [
                    { text: wmS?.public_talk?.title },
                    { text: ` (${t("schedules.duration-min", { count: 30 })})`, ...RunStyles.sm },
                  ],
                },
                {
                  cellAttr: { columnSpan: 2 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [
                    {
                      text: wmS?.speaker.id
                        ? nameOfUser(wmS?.speaker)
                        : isCOVisit
                          ? settings.circuit_overseer_name
                          : "",
                    },
                    {
                      text: isCOVisit
                        ? ` (${t("general.circuit-overseer")})`
                        : CongName(wmS?.speaker.congregation)
                          ? ` (${CongName(wmS?.speaker.congregation)})`
                          : "",
                      ...RunStyles.sm,
                    },
                  ],
                },
              ],
            },
            // Watchtower Study
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: headCellAttrGen(Color.wt),
                  paraAttr: { heading: HeadingLevel.HEADING_2 },
                  runs: [{ text: t("conganalysis.attendance.wt"), color: Color.wt }],
                },
              ],
            },
            // Watchtower Study - opening song
            // {
            //   rowAttr: {},
            //   cells: [
            //     {
            //       cellAttr: { columnSpan: 1 },
            //       paraAttr: { style: ParaStyle.time },
            //       runs: [{ text: getWMTimeAndAdd(5), color: Color.wt }],
            //     },
            //     {
            //       cellAttr: { columnSpan: 7 },
            //       paraAttr: { style: ParaStyle.secondary },
            //       runs: 0
            //         ? [{ text: songNum(0) }, { text: " — " }, { text: wmS?.opening_song?.title }]
            //         : [{ text: songNum(0) }],
            //     },
            //   ],
            // },
            // Watchtower Study - study
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getWMTimeAndAdd(isCOVisit ? 35 : 65), color: Color.wt }],
                },
                {
                  cellAttr: { columnSpan: 5 },
                  paraAttr: { style: ParaStyle.primary },
                  runs: [
                    { text: !isCOVisit ? songNum(0) + "  " : "", ...RunStyles.sm },
                    { text: t("conganalysis.attendance.wt") },
                    { text: ` (${t("schedules.duration-min", { count: isCOVisit ? 30 : 60 })})`, ...RunStyles.sm },
                  ],
                },
                {
                  cellAttr: { columnSpan: 2 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [
                    { text: name(wmS?.wt_conductor ?? settings.wt_conductor) },
                    ...(wmS?.wm_reader ? [{ text: ` · ${name(wmS.wm_reader)}`, ...RunStyles.sm }] : []),
                  ],
                },
              ],
            },
            //CO Talk
            ...(isCOVisit
              ? [
                  {
                    rowAttr: {},
                    cells: [
                      {
                        cellAttr: { columnSpan: 1 },
                        paraAttr: { style: ParaStyle.time },
                        runs: [{ text: getWMTimeAndAdd(30), color: Color.wt }],
                      },
                      {
                        cellAttr: { columnSpan: 5 },
                        paraAttr: { style: ParaStyle.primary },
                        runs: [
                          { text: settings.circuit_overseer_wm_svc_talk_title },
                          { text: ` (${t("schedules.duration-min", { count: 30 })})`, ...RunStyles.sm },
                        ],
                      },
                      {
                        cellAttr: { columnSpan: 2 },
                        paraAttr: { style: ParaStyle.name },
                        runs: [
                          { text: settings.circuit_overseer_name },
                          { text: ` (${t("general.circuit-overseer")})`, ...RunStyles.sm },
                        ],
                      },
                    ],
                  },
                ]
              : []),
            // Closing Prayer
            {
              rowAttr: {},
              cells: [
                {
                  cellAttr: { columnSpan: 1 },
                  paraAttr: { style: ParaStyle.time },
                  runs: [{ text: getWMTimeAndAdd(5), color: Color.wt }],
                },
                {
                  cellAttr: { columnSpan: 4 },
                  paraAttr: { style: ParaStyle.secondary },
                  runs: wmS?.closing_song?.number
                    ? [
                        { text: songNum(wmS?.closing_song?.number) },
                        { text: " — " },
                        { text: wmS?.closing_song?.title },
                      ]
                    : [{ text: "" }],
                },
                {
                  cellAttr: { columnSpan: 3 },
                  paraAttr: { style: ParaStyle.name },
                  runs: [
                    {
                      text: closingPrayer(wmS?.closeprayer),
                    },
                    { text: ` (${t("schedules.prayer")})`, ...RunStyles.sm },
                  ],
                },
              ],
            },
            //WM AVA
            ...avaOtherRows(wmAv),
            ...avaOtherRows(wmAtt),
            ...avaOtherRows(wmClean),

            //Other cleaning assignments
            ...(Object.entries(otherClean).length
              ? [
                  emptyRow(),
                  ...Object.entries(otherClean).flatMap(([date, assignments]) => {
                    return [
                      {
                        rowAttr: {},
                        cells: [
                          {
                            cellAttr: { ...avaCellAttr, columnSpan: 8 },
                            paraAttr: { style: ParaStyle.ava },
                            runs: [{ text: `${t("schedules.cleaning.title")} ${date}` }],
                          },
                        ],
                      },
                      ...avaOtherRows(assignments),
                    ];
                  }),
                ]
              : []),
          ]),
    ];
  });

  /**
   * Create document
   */
  const pageDimensions = pageDimensionsTwip(congPaperSize(settings.paper_size));
  const tableWidth = pageDimensions.width - PAGE_MARGIN * 2.5;
  const eachColumnWidth = convertInchesToTwip(0.9);
  const columnWidths: number[] = new Array(8).fill(eachColumnWidth);

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: { font: "Segoe UI Semilight", size: "10pt", color: Color.black },
          paragraph: { spacing: { before: 20 }, indent: { left: 2 * 20, right: 2 * 20 } },
        },
        title: {
          run: { font: "Arial", size: "16pt", bold: true },
          paragraph: { spacing: { before: 0 } },
        },
        heading1: {
          run: { size: "12pt", color: Color.white },
          paragraph: { spacing: { before: 0, after: 20 }, indent: { left: 3 * 20 } },
        },
        heading2: {
          paragraph: { spacing: { before: 2 * 20 } },
          run: { size: "10pt", bold: true, allCaps: true },
        },
      },
      paragraphStyles: [
        { id: ParaStyle.ava, name: ParaStyle.ava, run: { size: 8 * 2, color: Color.blue } },
        {
          id: ParaStyle.hall,
          name: ParaStyle.hall,
          run: { size: 7 * 2, color: Color.hg, bold: true },
          paragraph: { alignment: AlignmentType.END },
        },
        {
          id: ParaStyle.time,
          name: ParaStyle.time,
          run: { bold: true, size: 7 * 2 },
          paragraph: { alignment: AlignmentType.END },
        },
        { id: ParaStyle.primary, name: ParaStyle.primary, run: { bold: true } },
        { id: ParaStyle.secondary, name: ParaStyle.secondary, run: { color: Color.gray } },
        { id: ParaStyle.name, name: ParaStyle.name, run: {}, paragraph: { alignment: AlignmentType.END } },

        { id: ParaStyle.footer, name: ParaStyle.footer, run: { size: 7 * 2 } },
      ],
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: PAGE_MARGIN / 2,
              right: PAGE_MARGIN,
              bottom: PAGE_MARGIN,
              left: PAGE_MARGIN,
            },
            size: {
              width: pageDimensions.width,
              height: pageDimensions.height,
              orientation: PageOrientation.PORTRAIT,
            },
          },
        },
        headers: {
          default: new Header({
            children: [new Paragraph({ text: cong.name.toUpperCase(), heading: HeadingLevel.TITLE })],
          }),
        },
        children: pages.flatMap((page, pageIdx) => {
          const pageChildren: (Table | Paragraph)[] = [
            new Table({
              borders: TABLE_NO_BORDERS,
              width: { size: tableWidth, type: WidthType.DXA },
              layout: TableLayoutType.FIXED,
              columnWidths: columnWidths,
              rows: page.map((tr) => {
                return new TableRow({
                  ...tr.rowAttr,
                  children: tr.cells.map((cell) => {
                    return new TableCell({
                      width: { size: convertInchesToTwip(0.9), type: WidthType.DXA },
                      verticalAlign: VerticalAlign.CENTER,
                      margins: { top: 2 * 20, bottom: 2 * 20, left: 2 * 20, right: 2 * 20 },
                      ...cell.cellAttr,
                      children: cell.runs.length
                        ? [
                            new Paragraph({
                              ...(cell.paraAttr as IParagraphOptions),
                              children: cell.runs.map((r) => new TextRun({ ...r })),
                            }),
                          ]
                        : [],
                    });
                  }),
                });
              }),
            }),
          ];

          if (pageIdx < pages.length - 1) {
            pageChildren.push(new Paragraph({ children: [new PageBreak()] }));
          }

          return pageChildren;
        }),
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                style: "footer",
                alignment: AlignmentType.END,
                children: [new SimpleField("DATE")],
              }),
            ],
          }),
        },
      },
    ],
  });

  const blob = await Packer.toBlob(doc);
  const month = Month.fromDateString(from);
  const filename = `${t("schedules.combined")}_${month.year}-${month.month}.docx`;
  saveAs(blob, filename);
}

const avheading = (type: AVAttendantAssignmentType): string => {
  switch (type) {
    case AVAttendantAssignmentType.Console:
      return t("schedules.privileges.av-console");
    case AVAttendantAssignmentType.Stage:
      return t("schedules.privileges.stage");
    case AVAttendantAssignmentType.Mics:
      return t("schedules.privileges.microphones");
    case AVAttendantAssignmentType.Attendant:
      return t("schedules.privileges.attendant");
  }
  return type;
};

const noMeeting = (title: string, description: string, isMemorial: boolean): CombinedData[] => {
  return [
    {
      rowAttr: { height: { value: "15pc", rule: HeightRule.EXACT } },
      cells: [
        {
          cellAttr: { columnSpan: 8 },
          paraAttr: { heading: HeadingLevel.HEADING_1, alignment: AlignmentType.CENTER },
          runs: [
            { text: isMemorial ? t("schedules.events.memorial") : title, color: Color.black },
            { text: description, break: 1, ...RunStyles.sm },
          ],
        },
      ],
    },
  ];
};

const emptyRow = (): CombinedData => ({
  rowAttr: {},
  cells: [{ cellAttr: { columnSpan: 8 }, paraAttr: {}, runs: [] }],
});

const invisibleRow = (): CombinedData => ({
  rowAttr: { height: { value: 1, rule: HeightRule.EXACT } },
  cells: [
    {
      cellAttr: { columnSpan: 8 },
      paraAttr: {},
      runs: [],
    },
  ],
});

const avaOtherRows = (nameLabels: { name: string; label: string }[]): CombinedData[] => {
  if (!nameLabels.length) return [];
  return [
    {
      rowAttr: {},
      cells: chunkColumns(nameLabels, 4).map((ava) => ({
        cellAttr: avaCellAttr,
        paraAttr: { style: ParaStyle.ava },
        runs: ava.flatMap((a, i) => [
          { break: i ? 1 : 0, text: a.name, color: Color.av },
          { text: a.label ? ` (${a.label})` : "", ...RunStyles.avaSm },
        ]),
      })),
    },
  ];
};
