import { boldFont, getWidthForLine, halfInch, newPDFWithHeader, normalFont } from "../../../helpers/pdf";
import { jsPDF } from "jspdf";
import HourglassGlobals from "../../../helpers/globals";
import autoTable, { CellHookData, RowInput, Styles } from "jspdf-autotable";
import { Congregation, PublicTalk, Speaker } from "../../../types/scheduling/weekend";
import { nameOfUser } from "../../../helpers/user";
import { UserAppt } from "../../../types/user";
import { t } from "../../../helpers/locale";
import { localizedDayAndTime } from "../../../helpers/dateHelpers";
import i18n from "i18next";
import { decodeHtml } from "../../../helpers/util";
import { CongName } from "../common";

const margin = halfInch;
const bodyFontSize = 10;

export async function weekendExchangePDF(
  speakers: Speaker[],
  myCong: Congregation,
  contactInfo: string,
  locale: string,
) {
  const { doc, initY } = await newPDFWithHeader(t("schedules.weekend.speaker-list"), myCong.name, locale);
  let curY = initY + 0.05;

  curY = contactInfoHeader(doc, curY, myCong, contactInfo);
  curY = weekendExchange(doc, curY, UserAppt.Elder);
  const elders = speakers.filter((s) => s.appt === UserAppt.Elder && s.out);
  for (const elder of elders) {
    curY = speakerInfo(doc, curY, elder);
  }

  curY += 0.1; // gap between E & MS
  curY = weekendExchange(doc, curY, UserAppt.MS);
  const servants = speakers.filter((s) => s.appt === UserAppt.MS && s.out);
  for (const ms of servants) {
    curY = speakerInfo(doc, curY, ms);
  }

  doc.save(`${myCong.name}-SpeakerList.pdf`);
}

function weekendExchange(doc: jsPDF, initialY: number, appt: UserAppt.Elder | UserAppt.MS): number {
  const heading: RowInput[] = [
    [
      {
        content:
          appt === UserAppt.Elder ? t("statistics.publishers.elders") : t("statistics.publishers.ministerial-servants"),
        styles: { font: boldFont },
        colSpan: 2,
      },
    ],
  ];

  let lastEndY = 0;
  const headerFontSize = 10;
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const perOfTable = (x: number) => {
    return (x / 100) * tableWidth;
  };

  const colStyles: {
    [key: string]: Partial<Styles>;
  } = {
    0: {
      cellWidth: perOfTable(10),
      halign: "right",
    },
    1: {
      cellWidth: perOfTable(90),
      halign: "left",
    },
  };

  const drawTable = (
    doc: jsPDF,
    startY: number,
    head: RowInput[],
    body: RowInput[],
    willDrawCell?: (hookData: CellHookData) => void,
  ) => {
    autoTable(doc, {
      theme: "striped",
      startY: startY,
      head: head,
      body: body,
      margin: {
        left: margin,
        bottom: 0,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        fontSize: bodyFontSize,
        cellPadding: { top: 0.1, bottom: 0.1, left: 0.1 },
        valign: "middle",
      },
      headStyles: {
        font: normalFont,
        fillColor: "black",
        fontSize: headerFontSize,
        cellPadding: { top: 0.05, bottom: 0.05, left: 0.25 },
        halign: "left",
        valign: "middle",
      },
      columnStyles: colStyles,
      willDrawCell: willDrawCell,
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  };

  const theBody: RowInput[] = [];

  drawTable(doc, initialY, heading, theBody);
  return lastEndY;
}

export function speakerInfo(
  doc: jsPDF,
  initialY: number,
  speaker: Speaker,
  talkOverride?: PublicTalk | null,
  cong?: Congregation | null,
): number {
  const heading: RowInput[] = [
    [
      {
        content: nameOfUser(speaker, HourglassGlobals.nameFmt),
        styles: { font: boldFont, cellPadding: { top: 0.1, left: 0.25 } },
        colSpan: 2,
      },
    ],
  ];

  let lastEndY = 0;
  const headerFontSize = 10;
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const colWidthPadding = 0.03;
  const firstColWidth = Math.max(
    getWidthForLine(doc, bodyFontSize, t("userinfo.email"), false, colWidthPadding),
    getWidthForLine(doc, bodyFontSize, t("userinfo.cellphone"), false, colWidthPadding),
    getWidthForLine(doc, bodyFontSize, t("userinfo.homephone"), false, colWidthPadding),
    getWidthForLine(doc, bodyFontSize, t("userinfo.otherphone"), false, colWidthPadding),
    getWidthForLine(doc, bodyFontSize, t("schedules.general.talk"), false, colWidthPadding),
    getWidthForLine(doc, bodyFontSize, t("schedules.weekend.outlines"), false, colWidthPadding),
    getWidthForLine(doc, bodyFontSize, t("list.congregation.title"), false, colWidthPadding),
  );

  const colStyles: {
    [key: string]: Partial<Styles>;
  } = {
    0: {
      cellWidth: firstColWidth,
      halign: "right",
    },
    1: {
      cellWidth: tableWidth - firstColWidth,
      halign: "left",
    },
  };
  const drawTable = (
    doc: jsPDF,
    startY: number,
    head: RowInput[],
    body: RowInput[],
    willDrawCell?: (hookData: CellHookData) => void,
  ) => {
    autoTable(doc, {
      theme: "plain",
      startY: startY,
      head: head,
      body: body,
      margin: {
        left: margin,
        bottom: 0,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        font: normalFont,
        fontSize: bodyFontSize,
        cellPadding: { top: 0.02, bottom: 0.0, left: 0 },
        valign: "middle",
      },
      headStyles: {
        font: boldFont,
        fontSize: headerFontSize,
        fillColor: "white",
        textColor: "black",
        cellPadding: { top: 0.0, bottom: 0.0, left: 0 },
        halign: "left",
        valign: "middle",
      },
      columnStyles: colStyles,
      willDrawCell: willDrawCell,
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  };

  const theBody: RowInput[] = [];

  if (cong) {
    theBody.push([
      `${t("list.congregation.title")}:`,
      { content: CongName(cong), styles: { cellPadding: { left: 0.1 } } },
    ]);
  }
  if (speaker.email) {
    theBody.push([`${t("userinfo.email")}:`, { content: speaker.email, styles: { cellPadding: { left: 0.1 } } }]);
  }
  if (speaker.cellphone) {
    theBody.push([
      `${t("userinfo.cellphone")}:`,
      { content: speaker.cellphone, styles: { cellPadding: { left: 0.1 } } },
    ]);
  }
  if (speaker.homephone) {
    theBody.push([
      `${t("userinfo.homephone")}:`,
      { content: speaker.homephone, styles: { cellPadding: { left: 0.1 } } },
    ]);
  }
  if (speaker.otherphone) {
    theBody.push([
      `${t("userinfo.otherphone")}:`,
      { content: speaker.otherphone, styles: { cellPadding: { left: 0.1 } } },
    ]);
  }

  const tContent = (): string => {
    if (talkOverride) {
      return talkOverride.number ? `#${talkOverride.number} — ${talkOverride.title}` : talkOverride.title;
    }
    return `${speaker.public_talks.map((t) => `${t.number}`).join(", ")}`;
  };

  theBody.push([
    talkOverride ? `${t("schedules.general.talk")}:` : `${t("schedules.weekend.outlines")}:`,
    {
      content: tContent(),
      styles: { cellPadding: { left: 0.1 } },
    },
  ]);

  drawTable(doc, initialY, heading, theBody);
  return lastEndY;
}

function contactInfoHeader(doc: jsPDF, initialY: number, cong: Congregation, contactInfo: string): number {
  const heading: RowInput[] = [
    [
      {
        content: "",
        styles: { font: boldFont, cellPadding: { top: 0.1, left: 0.25 } },
      },
    ],
  ];

  let lastEndY = 0;
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const perOfTable = (x: number) => {
    return (x / 100) * tableWidth;
  };

  const colStyles: {
    [key: string]: Partial<Styles>;
  } = {
    0: {
      cellWidth: perOfTable(100),
    },
  };
  const drawTable = (
    doc: jsPDF,
    startY: number,
    head: RowInput[],
    body: RowInput[],
    willDrawCell?: (hookData: CellHookData) => void,
  ) => {
    autoTable(doc, {
      theme: "plain",
      startY: startY,
      head: head,
      body: body,
      margin: {
        left: margin,
        bottom: 0,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        font: normalFont,
        fontSize: bodyFontSize,
        cellPadding: { top: 0.02, bottom: 0.0, left: 0 },
        valign: "middle",
      },
      headStyles: {
        font: boldFont,
        fontSize: 0,
        fillColor: "white",
        textColor: "white",
        cellPadding: { top: 0.0, bottom: 0.0, left: 0 },
        halign: "left",
        valign: "middle",
      },
      columnStyles: colStyles,
      willDrawCell: willDrawCell,
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  };

  const theBody: RowInput[] = [];

  theBody.push([cong.address.trim()]);
  const trimmedPhone = decodeHtml(cong.phone.trim());
  if (trimmedPhone) {
    theBody.push([`${t("general.phone")}: ${trimmedPhone}\n`]);
  }
  theBody.push([localizedDayAndTime(cong.wm_dow, cong.wm_time, i18n.language, "long")]);
  theBody.push([]);
  theBody.push([{ content: t("schedules.settings.ptc-contact"), styles: { font: boldFont } }]);
  theBody.push([contactInfo.trim() + "\n"]);

  drawTable(doc, initialY, heading, theBody);
  return lastEndY;
}
