import { Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { jobApi } from "../../api/job";
import { useContext, useState } from "react";
import { JobStatus, ReminderResult } from "../../types/job";
import { HGContext } from "../../helpers/globals";
import { Cong } from "../../types/cong";
import useInterval from "../../helpers/useInterval";
import { getStatusCode } from "../../helpers/errors";
import { HGBugsnagNotify } from "../../helpers/bugsnag";

type ReminderProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  userId?: number;
};

export function RemindersModal(props: ReminderProps) {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [jobId, setJobId] = useState("");
  const [sendFailed, setSendFailed] = useState(false);
  const [showSendReminders, setShowSendReminders] = useState(true);
  const [stats, setStats] = useState<ReminderResult>();
  const [sendCompleted, setSendCompleted] = useState(false);

  useInterval(async () => {
    if (!jobId) return;
    try {
      const resp = await jobApi.getJob(jobId);
      switch (resp.status) {
        case JobStatus.Done: {
          setJobId("");
          setShowSpinner(false);
          setShowSendReminders(false);
          setSendCompleted(true);
          const reminderResult: ReminderResult = JSON.parse(resp.result);
          if (reminderResult) setStats(reminderResult);
          const newCong: Cong = { ...ctx.globals.cong!, lastreminder: resp.createdAt };
          ctx.setGlobals({ ...ctx.globals, cong: newCong });
          break;
        }
        case JobStatus.Failed:
          setShowSpinner(false);
          setSendFailed(true);
          setJobId("");
          break;
      }
    } catch (err: any) {
      if (getStatusCode(err) === 404) {
        setShowSpinner(false);
        setJobId("");
      } else {
        console.error("error fetching reminders job status", err);
        HGBugsnagNotify("jobStatusReminders", err);
      }
    }
  }, 3 * 1000);

  const handleClose = () => {
    setShowSpinner(false);
    setJobId("");
    setSendFailed(false);
    setSendCompleted(false);
    props.setShow(false);
  };

  const sendReminders = async () => {
    setShowSpinner(true);
    try {
      const resp = await jobApi.sendReminders(props.userId);
      setJobId(resp.id);
    } catch (err: any) {
      console.error("error sending reminders", err);
      HGBugsnagNotify("sendReminders", err);
      setSendFailed(true);
      setShowSpinner(false);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("nav.publishers.send-reminders")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!sendFailed && (props.userId ? t("reminders.one-user") : t("reminders.everyone"))}
        {!props.userId && ctx.globals.cong!.lastreminder && (
          <p>
            <b>{t("reminders.last-sent")}</b> {new Date(ctx.globals.cong!.lastreminder).toLocaleDateString()}
          </p>
        )}
        {showSpinner && (
          <div className="mt-3 d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        {sendFailed && <p>{t("reminders.error")}</p>}
        {!!stats && (
          <div>
            <p>
              {t("sendreminders.success.push-sent")} {stats.push_count}
            </p>
            <p>
              {t("sendreminders.success.email-sent")} {stats.email_sent}/{stats.email_count}
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showSendReminders && (
          <Button variant="primary" onClick={sendReminders} disabled={!!jobId}>
            {t("nav.publishers.send-reminders")}
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose} disabled={showSpinner}>
          {sendCompleted ? t("popup.error.button.ok") : t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
