import { Fragment, useContext, useState } from "react";
import HourglassGlobals, { HGContext } from "../helpers/globals";
import { useTranslation } from "react-i18next";
import { Button, Col, DropdownButton, Modal, Row, Spinner } from "react-bootstrap";
import { ArrowRightCircle, ExclamationOctagonFill, ExclamationTriangleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Month, yearMonth } from "../helpers/dateHelpers";
import { LanguagePicker } from "./languagePicker";
import { useMutation } from "@tanstack/react-query";
import { UserSelfUpdate } from "../types/user";
import userApi from "../api/user";
import { HGBugsnagNotify } from "../helpers/bugsnag";
import { isAppleICloudDomain } from "../helpers/email";

export function WarningModal() {
  const { t, i18n } = useTranslation();
  const ctx = useContext(HGContext);
  // we need this to be state so that selecting the language of the congregation doesn't cause the warning to disappear
  const [haveLanguageMismatch] = useState(i18n.language !== ctx.globals.cong!.locale.code);

  const handleClose = () => {
    ctx.setGlobals({ ...ctx.globals, warningsDismissed: true });
  };

  const flags = ctx.globals.flags;
  const firstUnsubmitted = (() => {
    if (!ctx.globals.lastSubmittedMonth) return;
    const [year, month] = yearMonth(ctx.globals.lastSubmittedMonth);
    // @ts-ignore noUncheckedIndexedAccess
    const m = new Month(year, month);
    m.addMonth();
    return m;
  })();

  const languageMismatch = haveLanguageMismatch && !ctx.globals.authUser.locales_id;
  // if the user has an email address that we support secure sign on with (Apple or Google) but they aren't using it,
  // tell them that they should
  const oauthPassword =
    !!ctx.globals.authUser.loginemail &&
    !ctx.globals.authUser.googleauth &&
    !ctx.globals.authUser.appleauth &&
    (ctx.globals.authUser.loginemail.toLowerCase().endsWith("@gmail.com") ||
      isAppleICloudDomain(ctx.globals.authUser.loginemail));

  const getWarningRows = (): JSX.Element[] => {
    const rows: JSX.Element[] = [];
    if (flags.deletePending) {
      rows.push(<WarningRow danger title={t("deletecong.pending")} link="/congProfile" onClose={handleClose} />);
    }
    if (flags.submitWarning && firstUnsubmitted) {
      rows.push(
        <WarningRow
          title={t("warning.submitreminder.body")}
          link={`/monthSummary/${firstUnsubmitted.year}/${firstUnsubmitted.month}`}
          onClose={handleClose}
        />,
      );
    }
    if (flags.congNotFound) {
      rows.push(
        <Row>
          <Col xs={1}>
            <ExclamationTriangleFill className="warning-color" size="2em" />
          </Col>
          <Col xs={9}>
            <p>{t("warning.cong-not-found")}</p>
            <a href="mailto:support@hourglass-app.com?subject=Congregation Not Found">support@hourglass-app.com</a>
          </Col>
        </Row>,
      );
    }
    if (flags.e2eIncomplete) {
      rows.push(<WarningRow title={t("warning.e2e-incomplete")} link="/e2eMigration" onClose={handleClose} />);
    }
    if (flags.congNotLinked) {
      rows.push(
        <WarningRow title={t("schedules.weekend.cong-not-linked")} link="/scheduling/wm" onClose={handleClose} />,
      );
    }
    if (languageMismatch) {
      rows.push(<LanguageWarning />);
    }
    if (oauthPassword) {
      rows.push(
        <WarningRow
          title={t("warning.google-password")}
          link={`/user/auth/${ctx.globals.authUser.id}`}
          onClose={handleClose}
        />,
      );
    }

    return rows;
  };

  const warningRows = getWarningRows();
  const showModal = warningRows.length > 0 && !ctx.globals.warningsDismissed && !ctx.globals.flags.tour;

  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("docs.monthsummary.warning.0")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {warningRows.map((wr, i) => (
          <Fragment key={i}>
            {wr}
            {i < warningRows.length - 1 && <hr />}
          </Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

function WarningRow(props: { title: string; link: string; onClose: () => void; danger?: boolean }) {
  return (
    <Row>
      <Col xs={1}>
        {props.danger ? (
          <ExclamationOctagonFill color="red" size="2em" />
        ) : (
          <ExclamationTriangleFill className="warning-color" size="2em" />
        )}
      </Col>
      <Col xs={9}>{props.title}</Col>
      <Col xs={2}>
        <Link to={props.link}>
          <Button variant="primary" onClick={props.onClose}>
            <ArrowRightCircle color="white" size="1.5em" />
          </Button>
        </Link>
      </Col>
    </Row>
  );
}

function LanguageWarning() {
  const { t, i18n } = useTranslation();
  const ctx = useContext(HGContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedLocaleId, setSelectedLocaleId] = useState(
    HourglassGlobals.locales().find((l) => l.code === i18n.language)?.id || 0,
  );
  const updateMeMutation = useMutation({ mutationFn: (userUpdate: UserSelfUpdate) => userApi.updateMe(userUpdate) });

  const saveLanguage = async () => {
    setShowSpinner(true);
    const userUpdate: UserSelfUpdate = { localeId: selectedLocaleId };
    try {
      await updateMeMutation.mutateAsync(userUpdate);
      const newGlobals = { ...ctx.globals };
      newGlobals.authUser.locales_id = selectedLocaleId;
      ctx.setGlobals(newGlobals);
    } catch (err: any) {
      console.error("error updating my language", err);
      HGBugsnagNotify("updateMyLanguage", err);
    } finally {
      setShowSpinner(false);
    }
  };

  const selectedLanguage = (_code: string, localeId: number) => {
    // find the locale ID for this language code
    if (!localeId) return;
    setSelectedLocaleId(localeId);
  };

  return (
    <Row>
      <Col xs={1}>
        <ExclamationTriangleFill className="warning-color" size="2em" />
      </Col>
      <Col xs={9}>
        <p>{t("warning.language-diff")}</p>
        <DropdownButton variant="primary" title={t("congprofile.language")}>
          <LanguagePicker onChange={selectedLanguage} enableAll />
        </DropdownButton>
        {showSpinner ? (
          <Spinner />
        ) : (
          <Button disabled={!selectedLocaleId} variant="primary" onClick={saveLanguage}>
            {t("general.save")}
          </Button>
        )}
      </Col>
    </Row>
  );
}
