export type PermissionUserGroup = {
  id: number;
  userId: number;
  groupId: number;
};

export type PermissionGroup = {
  id: number;
  name: string;
  locid: string;
};

export enum Permission {
  ViewUsers = "view users",
  ViewAddresses = "view addresses",
  UpdateAddresses = "update addresses",
  UpdateAddressesGroup = "update addresses mygroup",
  UpdateAttendance = "update attendance",
  UpdateEmergencycontactsAll = "update emergency contacts",
  UpdateEmergencycontactsGroup = "update emergency contacts mygroup",
  ViewEmergencycontacts = "view emergency contacts",
  ManagePermissions = "manage permissions",
  ViewPermissions = "view permissions",
  SendPush = "send push notification",
  ViewReportsAll = "view reports all",
  ViewReportsGroup = "view reports mygroup",
  UpdateReports = "update reports",
  UpdateReportsGroup = "update reports mygroup",
  UpdateReportsGroupSgo = "update reports mygroup sgo",
  ViewSummary = "view summary",
  ViewSummaryDetails = "view summary details all",
  ViewSummaryDetailsGroup = "view summary details mygroup",
  ViewSummaryRegpio = "view summary regpio",
  UpdateUsers = "update users",
  UpdateCongregation = "update congregation",
  UpdateUsersContactinfoGroup = "update users contactinfo mygroup",
  ViewSchedules = "view schedules",
  UpdateMidweekSchedules = "update midweek schedules",
  UpdateWeekendSchedules = "update weekend schedules",
  UpdateAVAttendantSchedules = "update av_attendant schedules",
  ViewUsersNotPublishing = "view users notpublishing",
  //field ministry schedules are about conducting meetings for service
  UpdateFieldMinistrySchedules = "update fm schedules",
  UpdateCleaningSchedules = "update cleaning schedules",
  UpdatePublicMinistrySchedules = "update pubministry schedules",
  UpdatePrivileges = "update privileges",
  UpdateTerritory = "update territory",
  ViewUsersMinimal = "view users minimal",
  SendInvitation = "send invitation",
  UpdateMaintenanceSchedules = "update maintenance schedules",
  UpdateCustomAssignments = "update custom assignments",
}
