import {
  ActiveElement,
  ArcElement,
  ChartData,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { useContext, useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import useReportTotals from "../api/reportTotals";
import { useUsers } from "../api/user";
import { localizedMonthYear } from "../helpers/dateHelpers";
import { HGContext } from "../helpers/globals";
import { canGetSummaryTotals } from "../helpers/permission";
import { userCompare } from "../helpers/user";
import { ReportTotals } from "../types/reportTotals";
import { FeatureModal } from "./featureModal";
import { QueryStatus } from "./queryStatus";
import { WarningModal } from "./warningModal";
import { HGPageTitle } from "./utils";

ChartJS.register(ArcElement, Legend, Tooltip);

type ChartTypeCounts = {
  linked: number;
  manual: number;
  missing: number;
};
type ChartTypeColors = {
  [K in keyof ChartTypeCounts]: string[];
};
type ChartTypeLabels = {
  [K in keyof ChartTypeCounts]: string;
};

export default function HourglassMain() {
  const ctx = useContext(HGContext);
  const cong = ctx.globals.cong;
  const reportTotalsQuery = useReportTotals({ enabled: canGetSummaryTotals() });

  return (
    <Row>
      <WarningModal />
      <FeatureModal />
      <Col xs={12} md={8} lg={8} className="mt-1">
        <HGPageTitle title={cong?.name ?? ""} />
        {reportTotalsQuery.data?.month && <MonthOverview reportTotals={reportTotalsQuery.data} />}
      </Col>
    </Row>
  );
}

function MonthOverview(props: { reportTotals: ReportTotals }) {
  const { t, i18n } = useTranslation();

  enum ShowUsersType {
    None,
    Self,
    Admin,
  }

  const [showUsers, setShowUsers] = useState(ShowUsersType.None);
  const [goToMissing, setGoToMissing] = useState(false);
  const usersQuery = useUsers();

  if (goToMissing) {
    return <Navigate to="/missing" replace />;
  }

  const queryStatus = QueryStatus(usersQuery);
  if (queryStatus !== null) return queryStatus;
  if (!usersQuery.data) return null;

  // this should be of type ChartOptions<"doughnut">
  // but the only way to avoid a console error related to navigating when clicking missing is to set legend.events = []
  const chartOptions: any = {
    plugins: {
      tooltip: {
        callbacks: {
          //we don't want to show the value since it's already in () in the label, so we override the tooltip label
          label(tooltipItem: TooltipItem<"doughnut">): string | string[] {
            return ` ${tooltipItem.label}`;
          },
        },
      },
      legend: {
        position: "bottom",
        events: [],
      },
    },
    aspectRatio: 16 / 9,
    maintainAspectRatio: false,
    onClick(_event: ChartEvent, elements: ActiveElement[]) {
      if (elements.length > 0) {
        // @ts-ignore noUncheckedIndexedAccess
        switch (elements[0].index) {
          case indices.missing:
            setGoToMissing(true);
            break;
          case indices.user:
            setShowUsers(showUsers === ShowUsersType.Self ? ShowUsersType.None : ShowUsersType.Self);
            break;
          case indices.admin:
            setShowUsers(showUsers === ShowUsersType.Admin ? ShowUsersType.None : ShowUsersType.Admin);
            break;
        }
      }
    },
  };

  const usersList = () => {
    const title = showUsers === ShowUsersType.Admin ? t("chart.legend.admin") : t("chart.legend.publisher");
    const userIds: Set<number> =
      showUsers === ShowUsersType.Admin
        ? new Set(props.reportTotals.enteredByAdminUserIds)
        : new Set(props.reportTotals.enteredBySelfUserIds);
    const users = usersQuery.data.filter((u) => userIds.has(u.id)).sort(userCompare);

    return (
      <div className="mt-3">
        <h5>{title}</h5>
        <Row>
          <Col xs="auto">
            <ListGroup variant="flush">
              {users.map((u) => (
                <ListGroup.Item key={u.id} action as={Link} to={`/user/${u.id}`}>
                  {u.displayName}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  };

  const typeLabels: ChartTypeLabels = {
    linked: t("chart.legend.publisher"),
    manual: t("chart.legend.admin"),
    missing: t("chart.legend.missing"),
  };

  const [chartData, totalCount, indices] = getChartData(typeLabels, props.reportTotals);

  return (
    <div>
      <h3>{localizedMonthYear(props.reportTotals.month, i18n.language)}</h3>
      <div style={{ position: "relative", height: "40vh", width: "100%", maxWidth: "400px" }}>
        {totalCount ? <Doughnut data={chartData} options={chartOptions} /> : t("popup.no-reports.body")}
      </div>
      {showUsers !== ShowUsersType.None && usersList()}
    </div>
  );
}

type DataIndices = {
  missing?: number;
  admin?: number;
  user?: number;
};

function getChartData(
  typeLabels: ChartTypeLabels,
  reportTotals: ReportTotals,
): [ChartData<"doughnut">, number, DataIndices] {
  const typeCounts: ChartTypeCounts = {
    linked: reportTotals.enteredBySelfUserIds.length,
    manual: reportTotals.enteredByAdminUserIds.length,
    missing: reportTotals.missingUserIds.length,
  };

  const typeColors: ChartTypeColors = {
    linked: ["#00ff00", "#66ff66"],
    manual: ["#ff0000", "#ff6666"],
    missing: ["#aaaaaa", "#cccccc"],
  };

  const data = [];
  const labels = [],
    bgColors = [],
    hoverBGColors = [];

  let total = 0;
  let chartKey: keyof ChartTypeLabels;
  let missingIndex: number | undefined;
  let adminIndex: number | undefined;
  let userIndex: number | undefined;
  let idx = 0;
  for (chartKey in typeLabels) {
    if (!Object.hasOwn(typeLabels, chartKey)) continue;
    total += typeCounts[chartKey];
    if (typeCounts[chartKey] > 0) {
      labels.push(`${typeLabels[chartKey]} (${typeCounts[chartKey]})`);
      data.push(typeCounts[chartKey]);
      bgColors.push(typeColors[chartKey][0]);
      hoverBGColors.push(typeColors[chartKey][1]);
      switch (chartKey) {
        case "missing":
          missingIndex = idx;
          break;
        case "linked":
          userIndex = idx;
          break;
        case "manual":
          adminIndex = idx;
          break;
      }
      idx++;
    }
  }

  return [
    {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: bgColors,
          hoverBackgroundColor: hoverBGColors,
        },
      ],
    },
    total,
    { admin: adminIndex, missing: missingIndex, user: userIndex },
  ];
}
