import { getDayjs, ISODateFormat } from "./dateHelpers";

export function getStoredDate(kind = "mm"): Date | undefined {
  try {
    if (!window.localStorage) return;
    const stored = window.localStorage.getItem(dateKey(kind));
    if (!stored) return;

    //all dates are stored as YYYY-MM-DD
    const day = getDayjs(stored);
    return day.isValid() ? day.toDate() : undefined;
  } catch (_e) {
    //noop
  }
}

function dateKey(kind: string): string {
  return `hg_date_${kind}`;
}

function viewKey(kind: string): string {
  return `hg_view_${kind}`;
}

export function storeView(kind = "mm", view: string) {
  try {
    if (!window.localStorage) return;
    window.localStorage.setItem(viewKey(kind), view);
  } catch (_e) {
    //noop
  }
}

export function storeDate(kind = "mm", date: Date) {
  try {
    if (!window.localStorage) return;
    window.localStorage.setItem(dateKey(kind), getDayjs(date).format(ISODateFormat));
  } catch (_e) {
    //noop
  }
}

export function clearStoredDate(kind = "mm") {
  try {
    if (!window.localStorage) return;
    window.localStorage.removeItem(dateKey(kind));
  } catch (_e) {
    //noop
  }
}

export function getStoredView(kind = "mm"): string | undefined {
  try {
    if (!window.localStorage) return;
    const stored = window.localStorage.getItem(viewKey(kind));
    return stored ? stored : undefined;
  } catch (_e) {
    //noop
  }
}

export function storeNumber(kind: string, value: number) {
  try {
    if (!window.localStorage) return;
    window.localStorage.setItem("hg_" + kind, `${value}`);
  } catch (_e) {
    //noop
  }
}

export function getStoredNumber(kind: string): number | null {
  try {
    if (!window.localStorage) return null;
    const value = window.localStorage.getItem("hg_" + kind);
    return value ? Number(value) : null;
  } catch (_e) {
    return null;
  }
}
