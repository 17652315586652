import { FormEvent, useState } from "react";
import { Card, Col, Container, Navbar, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import logo from "../../assets/hourglass-logo-brown.svg";

import { Step } from "../../types/register/registerState";
import { circuitApi } from "../../api/circuit";
import { ShowStatus, Status } from "../scheduling/common";

export function CoRegistration() {
  const { t } = useTranslation();
  return (
    <Container fluid="md">
      <Navbar>
        <Navbar.Brand>
          <img src={logo} className="App-logo" alt="logo" />
          {t("register.heading")}
        </Navbar.Brand>
      </Navbar>
      <RegistrationStep />
    </Container>
  );
}

function RegistrationStep() {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [regStep, setRegStep] = useState(Step.Init);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [jwpub, setJwpub] = useState("");
  const [circuitName, setCircuitName] = useState("");
  const [status, setStatus] = useState(Status.Null);

  const handleStepOne = (e: FormEvent<HTMLFormElement>) => {
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setRegStep(Step.Info);
  };

  const handleSubmit = async () => {
    const payload = `Name: ${name}
Email: ${email}
JWPub: ${jwpub}
Circuit: ${circuitName}`;

    try {
      setStatus(Status.Pending);
      await circuitApi.coReg(payload);
      setStatus(Status.Success);
      setTimeout(() => {
        setRegStep(Step.Finish);
      }, 1000);
    } catch {
      setStatus(Status.Failure);
    }
  };

  switch (regStep) {
    case Step.Init:
      return (
        <Form noValidate validated={validated} onSubmit={handleStepOne}>
          <Card style={{ maxWidth: "40rem" }}>
            <Card.Header>{t("circuit.co-signup")}</Card.Header>
            <Card.Body>
              <Form.Group as={Row} className="mb-3" controlId="name">
                <Form.Label column sm="5">
                  {t("general.name")}
                </Form.Label>
                <Col sm="7">
                  <Form.Control required autoFocus value={name} onChange={(e) => setName(e.target.value)} />
                  <Form.Control.Feedback type="invalid">{t("genereal.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="personalEmail">
                <Form.Label column sm="5">
                  <div>{t("circuit.personal-email")}</div>
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    required
                    type="email"
                    placeholder="email@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">{t("genereal.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="jwpubEmail">
                <Form.Label column sm="5">
                  {t("circuit.jwpub-email")}
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    required
                    type="email"
                    placeholder="email@jwpub.org"
                    value={jwpub}
                    onChange={(e) => setJwpub(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">{t("genereal.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="circuitName">
                <Form.Label column sm="5">
                  {t("circuit.circuit-name")}
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    required
                    placeholder="NY-99"
                    value={circuitName}
                    onChange={(e) => setCircuitName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">{t("genereal.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group className="mb-3 float-end">
                <Form.Check
                  required
                  label={t("circuit.i-am-a-co")}
                  feedback={t("circuit.you-must-agree")}
                  feedbackType="invalid"
                />
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Row className="float-end">
                <Col>
                  <Button type="submit">{t("tour.button.next")}</Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Form>
      );
    case Step.Info:
      return (
        <Card style={{ maxWidth: "40rem" }}>
          <Card.Header>Verify</Card.Header>
          <Table borderless style={{ border: 0 }}>
            <tbody>
              <tr>
                <td className="fw-bolder">{t("general.name")}</td>
                <td className="fw-lighter">{name}</td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("circuit.personal-email")}</td>
                <td className="fw-lighter">{email}</td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("circuit.jwpub-email")}</td>
                <td className="fw-lighter">{jwpub}</td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("circuit.circuit-name")}</td>
                <td className="fw-lighter">{circuitName}</td>
              </tr>
            </tbody>
          </Table>
          <Card.Footer>
            <Row className="float-end">
              <Col>
                <ShowStatus status={status} />
                <Button variant="danger" className="ms-2" onClick={() => setRegStep(Step.Init)}>
                  {t("tour.button.back")}
                </Button>
                <Button variant="primary" className="ms-2" onClick={handleSubmit}>
                  {t("general.submit")}
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      );
    case Step.Finish:
      return <p className="fs-4">{t("circuit.will-contact")}</p>;
  }
}
