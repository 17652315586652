import HourglassGlobals from "./globals";
import { CollatorSingleton } from "./locale";
import { XSRFHeader } from "../api/const";

//we use this to try to keep heights consistent in a few places
//just rendering an empty string or a space doesn't cause the browser to end up with the same height as when
//there's actual text, but this seems to.
export const NBSP = "\u00A0";
export const guidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
export const MaxSongNumber = 159;

export function shallowEqualObjects<T extends object>(a: T | undefined, b: T | undefined): boolean {
  if ((a && !b) || (b && !a)) {
    return false;
  }

  if (a && b) {
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    for (const key in a) {
      if (a[key] !== b[key]) {
        return false;
      }
    }
  }

  return true;
}

export function shallowEqualArray<T extends object>(a: T[], b: T[]): boolean {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => shallowEqualObjects(val, b[index]))
  );
}

export function getCookie(name: string): string {
  try {
    const nameVal = document.cookie.split("; ").find((row) => row.startsWith(name + "="));
    if (!nameVal) return "";
    // @ts-ignore noUncheckedIndexedAccess
    return nameVal.split("=", 2)[1];
  } catch (_e) {
    return "";
  }
}

export function haveCookie(name: string): boolean {
  return !!getCookie(name);
}

export function supportsCookies(): boolean {
  if (!navigator.cookieEnabled) return false;

  try {
    // Create cookie
    const testCookie = "cookietest=1; SameSite=strict";
    document.cookie = testCookie;
    const ret = document.cookie.indexOf("cookietest=") !== -1;
    // Delete cookie
    document.cookie = testCookie + "; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
  } catch (_e) {
    return false;
  }
}

export function clearCookie(name: string) {
  const exp = "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
  document.cookie = name + exp;
}

// it appears that in some cases, removing the XSRFHeader cookie isn't working. possibly strange browser behavior.
// we are setting a flag in session storage to indicate that the user signed out or is not authenticated
// we then won't try any kind of auto-login if this is set. it's cleared when the login page displays.
const signOutFlagKey = "hg_signOut";
const signOutFlagValue = "1";

export function setSignOutFlag() {
  try {
    window.sessionStorage.setItem(signOutFlagKey, signOutFlagValue);
  } catch (_e) {
    // noop
  }
}

export function hasSignOutFlag(): boolean {
  try {
    return window.sessionStorage.getItem(signOutFlagKey) === signOutFlagValue;
  } catch (_e) {
    return false;
  }
}

export function clearSignOutFlag() {
  try {
    window.sessionStorage.removeItem(signOutFlagKey);
  } catch (_e) {
    //noop
  }
}

export function signOut(clearSub = true) {
  clearCookie(XSRFHeader);
  clearCookie("hglogin");
  setSignOutFlag();
  if (clearSub) {
    clearCookie(HourglassGlobals.GoogleSubCookie);
    clearCookie(HourglassGlobals.AppleSubCookie);
  }

  window.location.href = HourglassGlobals.AppBase;
}

//show 0, 1 or 2 decimal places as needed
export function round2(n: number): number {
  return Math.round(n * 100) / 100;
}

export function stringCompare(a?: string, b?: string): number {
  if (!a || !b) return 0;
  return CollatorSingleton.getInstance().compare(a, b);
}

export const HourMs = 1000 * 60 * 60;

//from a long stackoverflow on the best way to chunk an array...
export function chunk<T>(arr: T[], size: number): T[][] {
  return [...Array(Math.ceil(arr.length / size))].map((_, i) => arr.slice(size * i, size + size * i));
}

//for when we want to distribute elements of an array evenly across x columns
export function chunkColumns<T>(arr: T[], columns: number): T[][] {
  const rows = Math.floor(arr.length / columns);
  const rem = arr.length % columns;
  return [...Array(columns)].map((_, i) => {
    const start = i * rows + Math.min(i, rem);
    return arr.slice(start, start + rows + (i < rem ? 1 : 0));
  });
}

export function defaultMidweekDate(): Date {
  return import.meta.env.VITE_NO_BACKEND ? new Date(2021, 10, 1) : new Date();
}

export function isValidHttpUrl(string: string): boolean {
  try {
    const newUrl = new URL(string);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (_e) {
    return false;
  }
}

// extract removes a specific element from an array
export function extract<T>(arr: T[], value: T) {
  const idx = arr.indexOf(value);
  if (idx > -1) {
    arr.splice(idx, 1);
  }
  return arr;
}

export async function sha256hash(string: string) {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((bytes) => bytes.toString(16).padStart(2, "0")).join("");
}

export function numberArrayRange(start: number, stop: number, step = 1): number[] {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
}

export function awaitTimeout<T>(promise: Promise<T>, timeout: number, reason: string): Promise<T | void> {
  return Promise.race([
    promise,
    new Promise<void>((resolve, reject) =>
      setTimeout(() => (reason === undefined ? resolve() : reject(reason)), timeout),
    ),
  ]);
}

export function setsAreEqual<T>(a: Set<T>, b: Set<T>): boolean {
  return a.size === b.size && [...a].every((value) => b.has(value));
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

// move the element at fromIndex to toIndex and return the new array
export const arrayMove = <T>(arr: T[], fromIndex: number, toIndex: number): T[] => {
  const newArr = [...arr];
  // @ts-ignore index type warning
  newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
  return newArr;
};

export function isNonEmptyArray(item: any): boolean {
  if (!Array.isArray(item)) return false;
  return item.length !== 0;
}

export function decodeHtml(str: string): string {
  const item = document.createElement("textarea");
  item.innerHTML = str;
  return item.value;
}
