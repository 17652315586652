import Address from "../types/address";
import EmergencyContact from "../types/emergencycontact";
import { TerritoryAddress } from "../types/scheduling/territory";
import HourglassGlobals from "./globals";

export function addressIdMap(addresses: Address[]): Map<number, Address> {
  const addrMap = new Map<number, Address>();
  addresses.forEach((addr) => addrMap.set(addr.id, addr));
  return addrMap;
}

export function addressToString(address?: Address | TerritoryAddress): string {
  if (!address) return "";

  const addrParts = [address.line1];
  const attrs: (keyof Address)[] = ["line2"];
  // use the order defined in addrformat to construct it - this should get us closer to a localized representation
  const addrFmtArray = HourglassGlobals.cong!.country.addrfmt.split("");
  addrFmtArray.forEach((part) => {
    switch (part) {
      case "c":
        attrs.push("city");
        break;
      case "s":
        attrs.push("state");
        break;
      case "p":
        attrs.push("postalcode");
        break;
    }
  });
  const comma = new Set<keyof Address>(["state"]);
  switch (addrFmtArray[0]) {
    case "c":
      comma.add("city");
      break;
    case "p":
      comma.add("postalcode");
      break;
  }

  attrs.forEach((key) => {
    // @ts-ignore explicit-check
    const val = address[key];
    if (val && typeof val === "string") {
      addrParts.push(val);
    }
  });

  return (
    addrParts
      .filter((ap) => !!ap)
      .join(", ")
      //remove multiple spaces and leading/trailing whitespace
      .replace(/ {2,}/g, " ")
      .trim()
  );
}

export function emergencyContactToAddress(ec: EmergencyContact): Address {
  return {
    id: 0,
    line1: ec.line1 || "",
    line2: ec.line2,
    city: ec.city,
    state: ec.state,
    postalcode: ec.postalcode,
    country: ec.country,
  };
}

type StreetAddressComponents = {
  houseNumber: number;
  street: string;
};

const nonDigitRegex = /\D/g;

function streetAddrComponents(line1?: string): StreetAddressComponents | undefined {
  if (!line1) return;

  const commaSplit = line1.split(",");
  const streetNum = parseInt(commaSplit[commaSplit.length - 1]?.replace(nonDigitRegex, "") ?? "");
  if (commaSplit.length > 1 && streetNum >= 0) {
    // the Brazil format: "Street, 123"
    const street = commaSplit.slice(0, -1).join(", ").trim();
    return { houseNumber: streetNum, street: street };
  }

  // now we assume a format like "123 Street"
  const parts = line1.split(/\s+/);
  if (parts.length > 1) {
    const street = parts.slice(1).join(" ").trim();
    const streetNum = parseInt(parts[0]?.replace(nonDigitRegex, "") ?? "");
    if (street && streetNum >= 0) return { houseNumber: streetNum, street: street };
  }
}

export function addressCompare(a: Address | TerritoryAddress, b: Address | TerritoryAddress): number {
  if (a.line1 === b.line1) {
    if (a.line2 !== b.line2) {
      const al2Int = parseInt(a.line2 ?? "");
      const bl2Int = parseInt(b.line2 ?? "");
      if (al2Int && bl2Int) {
        if (al2Int === bl2Int) return 0;
        return al2Int < bl2Int ? -1 : 1;
      }
      return (a.line2 ?? "") < (b.line2 ?? "") ? -1 : 1;
    }
    // this return only happens if line1 and line2 are both equal
    return a.id < b.id ? -1 : 1;
  }
  // if we have different line1s, see if we can order by street then number
  // use a helper to get the relevant address parts, since formats in different countries can vary
  const aStreetComponents = streetAddrComponents(a.line1);
  const bStreetComponents = streetAddrComponents(b.line1);
  if (aStreetComponents && bStreetComponents) {
    if (aStreetComponents.street !== bStreetComponents.street) {
      // different streets. order by street
      return aStreetComponents.street < bStreetComponents.street ? -1 : 1;
    }
    // same street: order by number
    if (aStreetComponents.houseNumber !== bStreetComponents.houseNumber) {
      return aStreetComponents.houseNumber < bStreetComponents.houseNumber ? -1 : 1;
    }
  }

  return a.line1 < b.line1 ? -1 : 1;
}

export function territoryAddressCompare(a: TerritoryAddress, b: TerritoryAddress): number {
  if (a.sortOrder === b.sortOrder) return addressCompare(a, b);
  return a.sortOrder - b.sortOrder;
}

export function addressCompareDNCFirst(a: TerritoryAddress, b: TerritoryAddress): number {
  if (a.dnc && !b.dnc) return -1;
  if (b.dnc && !a.dnc) return 1;
  return addressCompare(a, b);
}
