import { Congregation, Speaker, WMSchedule } from "../../../types/scheduling/weekend";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import HourglassGlobals, { HGContext, PythonI18nInterpolation } from "../../../helpers/globals";
import {
  getDayjs,
  isAfterToday,
  localizedDateRange,
  localizedDayAndTime,
  Month,
  stringToLocaleDate,
  weekOf,
  weekOfString,
} from "../../../helpers/dateHelpers";
import { Button, Modal } from "react-bootstrap";
import { Permission } from "../../../types/permission";
import { ISODateString } from "../../../types/date";
import { ExclamationTriangleFill, SlashCircle } from "react-bootstrap-icons";
import { nameOfUser } from "../../../helpers/user";
import { NameFormat } from "../../../types/cong";
import User from "../../../types/user";
import { AssignmentNotification } from "../../../types/scheduling/meetings";
import { Events } from "../../../types/scheduling/events";

export function CongWeekendMeetingTime(props: { cong: Congregation }) {
  const { t, i18n } = useTranslation();
  const myCong = useContext(HGContext).globals.cong!;

  if (!props.cong.wm_dow || !props.cong.wm_time) return null;
  return (
    <div className="mt-2">
      {localizedDayAndTime(props.cong.wm_dow, props.cong.wm_time, i18n.language, "long")}
      {props.cong.timezone_name !== myCong.timezone.name && !!props.cong.timezone_name && (
        <span className="ms-2">({props.cong.timezone_name})</span>
      )}
      {!!props.cong.future_wm_dow && !!props.cong.future_wm_time && isAfterToday(props.cong.future_start_date) && (
        <div className="warn_text">
          {localizedDayAndTime(props.cong.future_wm_dow, props.cong.future_wm_time, i18n.language, "long")} (
          {t("congprofile.beginning-date", {
            interpolation: PythonI18nInterpolation,
            date: stringToLocaleDate(props.cong.future_start_date, i18n.language),
          })}
          )
        </div>
      )}
    </div>
  );
}

export function BlockAcceptModal(props: { show: boolean; setShow: (show: boolean) => void }) {
  const { t } = useTranslation();
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>{t("schedules.weekend.exchange")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("schedules.weekend.must-enable-speakers")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setShow(false)}>
          {t("popup.error.button.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function canUpdateWeekendSchedules(): boolean {
  return HourglassGlobals.permissions.has(Permission.UpdateWeekendSchedules);
}

export type TalkDate = {
  talkNumber: number;
  date: ISODateString;
  s147?: string;
};
// sometimes there is direction not to schedule certain talks after a certain date. That list goes here.
const UnavailablePublicTalks: TalkDate[] = [
  { talkNumber: 47, date: "2019-04-01" },
  { talkNumber: 103, date: "2022-09-01" },
  { talkNumber: 82, date: "2023-01-01", s147: "2022-10" },
  { talkNumber: 112, date: "2023-06-01", s147: "2023-03" },
  { talkNumber: 131, date: "2023-09-01", s147: "2023-03" },
  { talkNumber: 132, date: "2023-09-01", s147: "2023-08" },
  { talkNumber: 123, date: "2024-05-01", s147: "2024-02" },
];

export function outlineDisabled(talkNumber: number, date: ISODateString): TalkDate | undefined {
  const djs = getDayjs(date);
  return UnavailablePublicTalks.find((td) => {
    const afterDjs = getDayjs(td.date);
    return talkNumber === td.talkNumber && !djs.isBefore(afterDjs);
  });
}

export function DisabledPublicTalkIndicator(props: { talkDate: TalkDate }) {
  const { i18n } = useTranslation();
  const disabledMonth = props.talkDate?.s147 ? Month.fromString(props.talkDate.s147) : undefined;

  return (
    <p className="text-muted hg-small-label mb-0">
      <SlashCircle className="me-1" color="red" />
      {!!disabledMonth && <>S-147 {disabledMonth.toLocaleString(i18n.language)}</>}
    </p>
  );
}

export function weekendLocalizedDateRange(week: ISODateString, locale: string): string {
  const saturday = getDayjs(week, true).isoWeekday(6);
  const sunday = saturday.isoWeekday(7);
  return localizedDateRange(saturday.toDate(), sunday.toDate(), locale);
}

export function weekendDateRange(week: ISODateString): Date[] {
  const saturday = getDayjs(week).isoWeekday(6);
  const sunday = saturday.isoWeekday(7);
  return [saturday.toDate(), sunday.toDate()];
}

export function SpeakerName(props: {
  speaker: Speaker | User;
  nameFmt?: NameFormat;
  full?: boolean;
  notOutOverride?: boolean;
}) {
  return (
    <div className="d-flex flex-column">
      {nameOfUser(props.speaker, props.nameFmt, props.full)}
      <SpeakerNotOutgoingWarning speaker={props.speaker} notOutOverride={props.notOutOverride} />
    </div>
  );
}

export function SpeakerNotOutgoingWarning(props: { speaker: Speaker | User; notOutOverride?: boolean }) {
  const { t } = useTranslation();

  const showNoOutWarning =
    ((props.speaker as Speaker).out !== undefined && !(props.speaker as Speaker).out) || props.notOutOverride;

  if (!showNoOutWarning) return null;
  return (
    <span className="text-muted hg-small-label ms-2 fw-lighter">
      <ExclamationTriangleFill color="gray" className="me-1" />
      {t("schedules.weekend.speaker-not-outgoing")}
    </span>
  );
}

export const noMeetingEvents = new Set([Events.ca, Events.rc, Events.wmem]);

export function needSendAssignments(
  langGroupId: number,
  notifications?: AssignmentNotification[],
  schedules?: WMSchedule[],
): boolean {
  if (!Array.isArray(notifications)) return false;
  if (!Array.isArray(schedules)) return false;

  // if there are at least 2 chairman assigned for the month, and we have a week with no notifications, return true
  const chairmanAssigned = schedules
    .map((s): number => (s.wm_chairman ? 1 : 0))
    .reduce((sum, current) => sum + current, 0);

  if (chairmanAssigned < 1) return false;

  const relevantNotifications = notifications?.filter((n) => n.congregationId === langGroupId);

  const notificationWeeks = new Set<ISODateString>(relevantNotifications.map((n) => weekOfString(n.date)) ?? []);
  const thisWeek = weekOf(new Date());

  // don't include weeks with no chairman or no meeting - e.g. assembly - from the determination
  return schedules
    .filter((s) => !!s.wm_chairman)
    .filter((s) => !s.event || !noMeetingEvents.has(s.event.event))
    .some((s) => !getDayjs(s.date).isBefore(thisWeek, "day") && !notificationWeeks.has(s.date));
}
