import dayjs from "dayjs";
import i18n from "i18next";
import { useEffect, useRef } from "react";
import { Alert, Dropdown, Form, Spinner } from "react-bootstrap";
import { CheckCircleFill, ExclamationTriangleFill, XSquareFill } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCongSettings } from "../../api/cong";
import { useUsers } from "../../api/user";
import { weekOfString, wmDateTime } from "../../helpers/dateHelpers";
import HourglassGlobals, { GlobalContext } from "../../helpers/globals";
import { languageGroupName } from "../../helpers/langGroups";
import { CollatorSingleton, t } from "../../helpers/locale";
import { nameOfUser, userCompare } from "../../helpers/user";
import { DateRange, ISODateString } from "../../types/date";
import { AVAttendantAsset, AVAttendantAssignmentType } from "../../types/scheduling/avAttendant";
import { NotificationState, NotificationStatus } from "../../types/scheduling/meetings";
import { MidweekMeetingPartType } from "../../types/scheduling/midweek";
import { PublicWitnessingSchedule } from "../../types/scheduling/publicWitnessing";
import { CongSettings } from "../../types/scheduling/settings";
import { Congregation, PublicTalk, Speaker, SpeakerDirectExchange, WMCoreParts } from "../../types/scheduling/weekend";
import User from "../../types/user";
import { Warning } from "../alerts";
import ExclamationDiamondFillBlack from "./ExclamationDiamondFillBlack";
import { AssignmentSummary, AssignmentSummaryType, useAssignmentMap } from "./RecentAssignments";

export function UserMap(): Map<number, User> {
  const usersQuery = useUsers();
  return new Map<number, User>(usersQuery.data?.map((u) => [u.id, u]) ?? []);
}

export function notificationStatusToState(status?: NotificationStatus): NotificationState | undefined {
  switch (status) {
    case NotificationStatus.Pending:
      return NotificationState.Pending;
    case NotificationStatus.Declined:
      return NotificationState.Declined;
    case NotificationStatus.Complete:
      return NotificationState.Accepted;
    default:
      return NotificationState.Created;
  }
}

export enum Status {
  Null,
  Pending,
  Success,
  Failure,
}

export function OverlappingAssignment(props: { className?: string; label?: string }) {
  const { t } = useTranslation();
  return (
    <ExclamationDiamondFillBlack
      aria-label={t("schedules.potential-conflict")}
      className={`${props.className || ""}`}
      title={props.label}
    />
  );
}

export const StudentPartTypes = new Set<MidweekMeetingPartType>([
  MidweekMeetingPartType.Reading,
  MidweekMeetingPartType.InitialCall,
  MidweekMeetingPartType.RV,
  MidweekMeetingPartType.StudentBibleStudy,
  MidweekMeetingPartType.StudentTalk,
  MidweekMeetingPartType.HH,
]);

// useHaveMMConflict determines if the supplied user has a potential conflict for a MM assignment on the given date.
// the langGroupId parameter specifies the language group currently being viewed and is excluded from comparisons
export function useHaveMMConflict(
  userId: number,
  date: ISODateString,
  dateRange: DateRange,
  langGroupId: number,
): string | undefined {
  const hostMMDow = HourglassGlobals.cong!.mmdow;
  // get assignments & settings for all language groups that meet on the same day. sorted so that the hooks are called
  // in the exact same order each time
  const sameDayCongIds = [HourglassGlobals.cong, ...HourglassGlobals.language_groups]
    .filter((c) => c!.mmdow === hostMMDow)
    .map((c) => c!.id)
    .sort((a, b) => a - b);

  // allAssignments is Map<langGroupId, Map<userId, AssignmentSummary[]>>
  const allAssignments = new Map<number, Map<number, AssignmentSummary[]>>();
  const congSettings = new Map<number, CongSettings | undefined>();
  for (const cid of sameDayCongIds) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    allAssignments.set(cid, useAssignmentMap(dateRange, true, cid));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    congSettings.set(cid, useCongSettings(cid).data);
  }

  if (!userId) return;

  // first check to see if there are any A/V conflicts within the same language group: someone assigned a MM part
  // and an AVA assignment for the same meeting
  const groupSettings = congSettings.get(langGroupId);
  const groupAssignments = allAssignments.get(langGroupId);
  const avaTitle = i18n.t("schedules.ava.title");
  if (
    groupSettings &&
    groupAssignments &&
    haveAVAAssignment(userId, date, groupSettings, "midweek", groupAssignments)
  ) {
    const avaAssignment = haveAVAAssignment(userId, date, groupSettings, "midweek", groupAssignments) as string;
    return i18n.t(avaAssignment);
  }

  // see if they have any of the following within this language group and on this date:
  // 1. multiple student parts
  // 2. a student part and being the chairman
  const thisGroupAssignments = allAssignments.get(langGroupId)?.get(userId);
  if (thisGroupAssignments) {
    const todayAssignments = [...thisGroupAssignments.values()].filter((as) => as.date === date);
    const studentAssignments = todayAssignments.filter((as) => StudentPartTypes.has(as.type as MidweekMeetingPartType));
    const chairmanAssignments = new Set<AssignmentSummaryType>([
      MidweekMeetingPartType.Chairman,
      MidweekMeetingPartType.Chairman2,
      MidweekMeetingPartType.Chairman3,
    ]);
    const isChairman = todayAssignments.some((as) => chairmanAssignments.has(as.type as MidweekMeetingPartType));

    const studentText = i18n.t("schedules.midweek.student");
    if (studentAssignments.length > 1) {
      return studentText;
    }
    if (studentAssignments.length > 0 && isChairman) {
      const chairmanText = i18n.t("schedules.chairman");
      return `${chairmanText} | ${studentText}`;
    }
  }

  // if there are no other groups meeting on the same day, nothing else to do
  if (sameDayCongIds.length < 2) return;

  const otherGroupAssignments = [...allAssignments.entries()].filter((v) => v[0] !== langGroupId);
  const avConflict = otherGroupAssignments.find((v) => {
    const settings = congSettings.get(v[0]);
    if (settings) return haveAVAAssignment(userId, date, settings, "midweek", v[1]);
  });
  if (avConflict) {
    const conflictGroupId = avConflict[0];
    const avaAssignment = avConflict[1].get(0)?.[0];
    const title = avaAssignment ? t(AVAttendantAsset[avaAssignment.type as AVAttendantAssignmentType]) : avaTitle;
    const groupName =
      conflictGroupId !== langGroupId ? languageGroupName(conflictGroupId, congSettings.get(conflictGroupId)) : "";
    if (groupName) return `${title} – ${groupName}`;
    return title;
  }

  const isMMAssignment = (type: AssignmentSummaryType): boolean => {
    return Object.values(MidweekMeetingPartType).includes(type as MidweekMeetingPartType);
  };

  // now see if the user has an MM part in a different language group
  const mmConflict = [...allAssignments.entries()]
    .filter((v) => v[0] !== langGroupId)
    .find((v) => v[1].get(userId)?.some((as) => as.date === date && isMMAssignment(as.type)));
  if (mmConflict) {
    const mmTitle = i18n.t("conganalysis.attendance.midweek");
    const conflictGroupId = mmConflict[0];
    const groupName = languageGroupName(conflictGroupId, congSettings.get(conflictGroupId));
    return `${mmTitle} – ${groupName}`;
  }
}

// See if the user has an ava assignment.
// This can then be made visible to alert the scheduler to the possible conflict.
// returns translation string for the ava assignment type
export function haveAVAAssignment(
  userId: number,
  date: ISODateString,
  settings: CongSettings,
  meetingType: "midweek" | "weekend",
  assignmentMap: Map<number, AssignmentSummary[]>,
): string | undefined {
  // we either need to look for AVA assignments on monday, or sunday
  // if it's midweek meeting, or we schedule AVA weekly, then look for monday. otherwise, sunday
  const matchDate = weekOfString(date, meetingType === "midweek" || settings?.ava_schedule_weekly ? 1 : 7);

  const isCorrectType = (type: AssignmentSummaryType): boolean => {
    return Object.values(AVAttendantAssignmentType).includes(type as AVAttendantAssignmentType);
  };
  const assignments = assignmentMap.get(userId)?.find((as) => isCorrectType(as.type) && as.date === matchDate);
  if (!assignments) return;
  return AVAttendantAsset[assignments.type as AVAttendantAssignmentType];
}

// See if the user has a WM core assignment
// This can then be made visible to alert the scheduler to the possible conflict.
export function haveWMCoreAssignment(
  userId: number,
  date: ISODateString,
  assignmentMap: Map<number, AssignmentSummary[]>,
): boolean {
  const isCorrectType = (type: AssignmentSummaryType): boolean => {
    return Object.values(WMCoreParts).includes(type as WMCoreParts);
  };

  return assignmentMap.get(userId)?.some((as) => isCorrectType(as.type) && as.date === date) ?? false;
}

export function havePWAssignment(
  userId: number,
  date: ISODateString,
  pwSchedules: PublicWitnessingSchedule[],
  congregation?: Congregation,
) {
  if (!congregation) return false;
  const talkDatetime = wmDateTime(date, congregation, []);
  const pwAssignments = pwSchedules.filter((pw) => pw.assignee_id === userId);

  return pwAssignments.some((pw) => {
    const durationInHours = dayjs.duration(parseFloat(pw.duration), "hours");
    // take the date and time in datetime but put it into the congregation's time zone
    // we erroneously send datetime in UTC and should change it to be naive to avoid confusion
    const pwStart = dayjs.tz(pw.datetime, congregation?.timezone_name);
    const from = pwStart.subtract(1, "hour");
    const to = pwStart.add(durationInHours).add(3, "hour"); //2h meeting time + 1h buffer
    return from.isBefore(talkDatetime) && to.isAfter(talkDatetime);
  });
}

export function sortedWMCongregations(congs?: Congregation[]): Congregation[] {
  return congs ? congs.filter((c) => c.direct_exchange !== SpeakerDirectExchange.Proposed).sort(congCompare) : [];
}

export function ShowStatus(props: { status: Status; size?: "sm" | string }): JSX.Element {
  switch (props.status) {
    case Status.Pending:
      return <Spinner animation="grow" size={(props.size === "sm" && props.size) || undefined} />;

    case Status.Success:
      return <CheckCircleFill color="green" size={props.size ?? "2em"} className="ms-1 mt-1" />;

    case Status.Failure:
      return <XSquareFill color="red" size={props.size ?? "2em"} className="ms-1 mt-1" />;

    default:
      return <></>;
  }
}

export function CongName(cong: Congregation | null | undefined, ctx?: GlobalContext, settings?: CongSettings): string {
  if (!cong) return "";
  if (!!ctx && !!settings && cong.id === ctx.globals.cong!.id) {
    return settings.congregation_display_name || cong.name;
  }
  return cong.custom_name || cong.name || "";
}

export function congCompare(a: Congregation, b: Congregation): number {
  return CollatorSingleton.getInstance().compare(a.name, b.name);
}

//we have a few places where we need to show some things like "other" that aren't real congregations
export function dummyCongregation(id: number, name: string): Congregation {
  return {
    id: id,
    name: name,
    custom_name: null,
    address: "",
    phone: "",
    wm_dow: 0,
    wm_time: "",
    mm_dow: 0,
    mm_time: "",
    future_wm_dow: 0,
    future_wm_time: "",
    future_mm_dow: 0,
    future_mm_time: "",
    future_start_date: "",
    guid: "",
    notes: "",
    timezone_offset: "",
    timezone_name: "",
    direct_exchange: SpeakerDirectExchange.No,
  };
}

export function speakerCompare(a: Speaker, b: Speaker): number {
  const aName = nameOfUser(a, HourglassGlobals.nameFmt);
  const bName = nameOfUser(b, HourglassGlobals.nameFmt);
  return userCompare({ displayName: aName }, { displayName: bName });
}

export function publicTalkCompare(a: PublicTalk, b: PublicTalk): number {
  if (a.last_date_given === b.last_date_given) return a.number > b.number ? 1 : -1;
  return a.last_date_given > b.last_date_given ? 1 : -1;
}

export function NoOneWithPrivileges(props: { priv: string }) {
  return (
    <Dropdown.Item as={Link} to="/scheduling/privilege_matrix" key="no_users" style={{ whiteSpace: "normal" }}>
      <Warning
        text={
          <Trans
            i18nKey="schedules.privileges.warning"
            values={{ priv: props.priv }}
            components={{ bold: <strong /> }}
          />
        }
        className="fst-italic p-2 mt-3"
      />
    </Dropdown.Item>
  );
}

export function SendAssignmentsReminder(props: { className?: string }) {
  const { t } = useTranslation();

  return (
    <Alert variant="warning" className={props.className}>
      <ExclamationTriangleFill className="warning-color" /> {t("schedules.assignments.need-to-send")}
    </Alert>
  );
}

export function DropdownFilter(props: { filter: string; setFilter: (f: string) => void; setFocus?: boolean }) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const needSetFocus = props.setFocus;

  useEffect(() => {
    if (needSetFocus) {
      window.setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [needSetFocus]);

  const updateFilter = (filter: string) => {
    props.setFilter(filter);
  };

  return (
    <Dropdown.Item
      key="auto"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Form.Control
        ref={inputRef}
        autoFocus
        size="sm"
        placeholder={t("search.hint")}
        onChange={(e) => updateFilter(e.currentTarget.value)}
        value={props.filter}
      />
    </Dropdown.Item>
  );
}
