import { useQueryClient } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { useContext, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Dropdown, Modal, Spinner, Table } from "react-bootstrap";
import {
  ClipboardCheck,
  ClipboardPlus,
  EnvelopeFill,
  ExclamationTriangleFill,
  InfoCircleFill,
  PencilFill,
  PlusCircleFill,
  XCircleFill,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useAbsence } from "../../../api/absence";
import { useCongSettings } from "../../../api/cong";
import { jobApi } from "../../../api/job";
import { meetingsApi, useEvents, useNotifications } from "../../../api/meetings";
import { useUsers } from "../../../api/user";
import { useWMSchedules } from "../../../api/weekend";
import { hasAbsence } from "../../../helpers/absence";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import {
  Month,
  dateToString,
  getDayjs,
  isBeforeToday,
  localizedTime,
  stringToDate,
  stringToLongLocaleDate,
  weekOf,
  weekOfString,
  wmDate,
} from "../../../helpers/dateHelpers";
import { getStatusCode } from "../../../helpers/errors";
import HourglassGlobals, { HGContext } from "../../../helpers/globals";
import { selectedCong } from "../../../helpers/langGroups";
import useInterval from "../../../helpers/useInterval";
import { nameOfUser } from "../../../helpers/user";
import { renderWMTemplate } from "../../../helpers/wmTemplates";
import { assignmentNotificationQueryKey } from "../../../query/notification";
import { ISODateString, NotificationDates } from "../../../types/date";
import { JobStatus } from "../../../types/job";
import { Events } from "../../../types/scheduling/events";
import { NotificationType } from "../../../types/scheduling/meetings";
import { CongSettingTypes, CongSettings } from "../../../types/scheduling/settings";
import { Speaker, TalkMod, WMSchedule } from "../../../types/scheduling/weekend";
import { ActionsDropdown } from "../../buttons";
import SlashCircleFillReversed from "../../common/SlashCircleFillReversed";
import { QueryStatus } from "../../queryStatus";
import { useAssignmentMap } from "../RecentAssignments";
import { CongName, OverlappingAssignment, haveAVAAssignment, haveWMCoreAssignment } from "../common";
import { MonthScheduleMenu } from "../date";
import { EventAlert, EventBadge, noWeekendMeeting } from "../eventAlert";
import { NotificationIcon, NotificationStatusLegend, notificationStatePart } from "../notificationStatus";
import { canUpdateWeekendSchedules } from "./wm_common";
import { EditWeek } from "./wm_edit_week";

export function Incoming(props: {
  from: ISODateString;
  to: ISODateString;
  langGroupId: number;
  month: Date;
  setMonth: (d: Date) => void;
  monthCount: number;
  setMonthCount: (c: number) => void;
}) {
  const ctx = useContext(HGContext);
  const workingCong = selectedCong(props.langGroupId) ?? ctx.globals.cong!;
  // get a set of all local cong ids - host cong and all language groups
  const localCongIds = new Set<number>([ctx.globals.cong, ...ctx.globals.language_groups].map((c) => c!.id));
  const { t, i18n } = useTranslation();

  const [speaker, setSpeaker] = useState({} as Speaker);
  const [showSpeakerInfoModal, setShowSpeakerInfoModal] = useState(false);
  const [editMode, setEditMode] = useState("");
  const notificationDates: NotificationDates = { start: weekOfString(props.from), end: props.to };
  const notificationsQuery = useNotifications(notificationDates.start, notificationDates.end, NotificationType.PT);
  const [jobId, setJobId] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const queryClient = useQueryClient();
  const scheduleQuery = useWMSchedules(props.from, props.to, props.langGroupId);

  const absenceQuery = useAbsence();
  const year = stringToDate(props.from).getFullYear();
  const eventsQuery = useEvents(year);
  const congSettingsQuery = useCongSettings(props.langGroupId);
  const userAssignmentMap = useAssignmentMap(
    { from: props.from, to: props.to },
    canUpdateWeekendSchedules(),
    props.langGroupId,
  );
  // if there's a symposium speaker (speaker2), then fetch the users so we can display their name
  const usersQuery = useUsers({ enabled: scheduleQuery.data?.some((s) => !!s.speaker2) });

  useInterval(async () => {
    if (!jobId) return;
    try {
      const resp = await jobApi.getJob(jobId);
      switch (resp.status) {
        case JobStatus.Pending:
          return;
        case JobStatus.Done:
        case JobStatus.Failed:
          setJobId("");
          setShowSpinner(false);
          break;
      }
      await queryClient.invalidateQueries({
        queryKey: assignmentNotificationQueryKey(NotificationType.PT, notificationDates.start, notificationDates.end),
      });
    } catch (err: any) {
      if (getStatusCode(err) === 404) {
        setShowSpinner(false);
        setJobId("");
      } else {
        console.error("wm pt in: error fetching job status", err);
        HGBugsnagNotify("wmIncomingJobStatus", err);
      }
    }
  }, 3 * 1000);

  const queryStatus = QueryStatus(scheduleQuery, congSettingsQuery);
  if (queryStatus !== null) {
    if (scheduleQuery.isError) {
      const err = scheduleQuery.error;
      switch (getStatusCode(err)) {
        case 401:
        case 426:
          break;
        default:
          HGBugsnagNotify("wmIncomingSchedule", err);
      }
    }
    if (congSettingsQuery.isError) {
      const err = congSettingsQuery.error;
      switch (getStatusCode(err)) {
        case 401:
          break;
        default:
          HGBugsnagNotify("wmIncomingCongSettings", err);
      }
    }
    return queryStatus;
  }
  if (!scheduleQuery.data || !congSettingsQuery.data) return null;

  const sendAssignments = async () => {
    setShowSpinner(true);
    try {
      const job = await meetingsApi.sendAllNotifications(
        notificationDates.start,
        notificationDates.end,
        NotificationType.PT,
        props.langGroupId,
      );
      setJobId(job.id);
      await queryClient.invalidateQueries({
        queryKey: assignmentNotificationQueryKey(NotificationType.PT, notificationDates.start, notificationDates.end),
      });
    } catch (err: any) {
      setShowSpinner(false);
      console.error("wm pt in: error sending notifications", err);
      HGBugsnagNotify("wmIncomingSendNotifications", err);
    }
  };

  const haveLocalSpeaker = scheduleQuery.data.some((s) => localCongIds.has(s.speaker?.congregation?.id ?? 0));
  let haveNotification = false;

  const Weeks = scheduleQuery.data?.map((s, i) => {
    let week: JSX.Element;
    const meetingDate = stringToDate(wmDate(s.date, workingCong, eventsQuery.data));
    const hasConflictingAbsence = s.speaker?.userId
      ? hasAbsence(s.speaker.userId, meetingDate, absenceQuery.data)
      : false;
    const notification = notificationsQuery.data?.find((n) => n.date === s.date && n.part === s.id);
    if (notification) haveNotification = true;
    const notificationState = notificationStatePart(s.speaker?.userId ?? 0, notification);

    const haveOverlappingAssignment = (): boolean => {
      if (!s.speaker?.userId || localCongIds.has(s.speaker?.congregation?.id ?? 0)) return false;
      const avaAssignment = haveAVAAssignment(
        s.speaker?.userId,
        s.date,
        congSettingsQuery.data,
        "weekend",
        userAssignmentMap,
      );
      if (avaAssignment) {
        return true;
      }

      if (haveWMCoreAssignment(s.speaker.userId, s.date, userAssignmentMap)) return true;
      if (s.out) return s.out.some((pta) => pta.speaker?.userId === s.speaker.userId);
      return false;
    };

    const isPastWeek = getDayjs(s.date).isBefore(weekOf(new Date()), "day");

    const SpeakerInfo = (siProps: { schedule: WMSchedule; speaker2?: Speaker }): JSX.Element => {
      const speaker = siProps.speaker2 ? siProps.speaker2 : siProps.schedule.speaker;
      return (
        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
          <div className="d-flex flex-row align-items-center">
            <h4 className="fw-bold mb-0 me-2">
              {speaker2 ? nameOfUser(speaker) : PrintName(s, t, congSettingsQuery.data)}{" "}
            </h4>
            {s.confirmed && (
              <Badge bg="success" className="ms-1">
                <span>{t("schedules.weekend.confirmed")}</span>
              </Badge>
            )}
            {(localCongIds.has(speaker.congregation?.id ?? 0) || !!notification) && !isPastWeek && (
              <span className="mx-1 d-flex gap-1">
                <NotificationIcon nstate={notificationState} hasAbsence={hasConflictingAbsence} />
                {haveOverlappingAssignment() && <OverlappingAssignment className="" />}
              </span>
            )}
            {hasConflictingAbsence && (
              <SlashCircleFillReversed className="me-1" title={t("schedules.absence.conflicting")} />
            )}
          </div>
          <div className="d-flex flex-row align-items-center">
            {!!speaker.id && (
              <>
                <Button
                  size="sm"
                  variant="secondary"
                  className="border-0 rounded-pill p-2 d-flex align-items-center text-muted p-1"
                  onClick={() => {
                    setSpeaker(speaker);
                    setShowSpeakerInfoModal(true);
                  }}
                >
                  <InfoCircleFill size={16} />
                </Button>

                <TemplateActionButton
                  settings={congSettingsQuery.data}
                  schedule={siProps.schedule}
                  meetingDate={dateToString(meetingDate)}
                />

                {!!speaker2 &&
                  (s.talk_mod === TalkMod.CO || s.event?.event === Events.co || noWeekendMeeting(s.event)) && (
                    <ExclamationTriangleFill className="warning-color ms-2" />
                  )}
              </>
            )}
          </div>
        </div>
      );
    };

    function TemplateActionButton(props: { settings: CongSettings; schedule: WMSchedule; meetingDate: ISODateString }) {
      const [copied, setCopied] = useState(false);

      const schedule = props.schedule;
      const speakerEmail = schedule.speaker.email;
      const speaker = nameOfUser(schedule.speaker);
      const subject = t("email.templates.wm.public-talk-speaker-reminder.subject", {
        speaker,
        congregation: workingCong.name ?? "",
        date: stringToLongLocaleDate(props.meetingDate),
      });

      const renderTemplate = (): string =>
        renderWMTemplate(props.settings.wm_reminder_email_template, {
          speaker,
          talkTitle: schedule.public_talk?.number
            ? `${schedule.public_talk?.title} (${schedule.public_talk?.number})`
            : t("schedules.weekend.tbd"),
          date: stringToLongLocaleDate(props.meetingDate),
          time: localizedTime(HourglassGlobals.cong!.wmtime, i18n.language),
          congregation: workingCong.name,
          sender: HourglassGlobals.authUser.displayName,
        });

      const copyTemplateToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(renderTemplate());
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        } catch (err: any) {
          console.error("Unable to copy to clipboard", err);
        }
      };

      return (
        <Dropdown size="sm" as={ButtonGroup}>
          <Button
            variant="secondary"
            title={t("schedules.weekend.speaker.copy-reminder")}
            size="sm"
            className="py-2 ms-1 d-flex align-items-center"
            onClick={copyTemplateToClipboard}
          >
            {copied ? <ClipboardCheck color="green" size={18} /> : <ClipboardPlus size={18} />}
            <span className="ms-1">{t("general.copy")}</span>
          </Button>
          {speakerEmail && (
            <>
              <Dropdown.Toggle split variant="secondary" size="sm" />
              <Dropdown.Menu className="dropdown-menu-sm">
                <Dropdown.Item
                  className="d-flex align-items-center dropdown-item-sm"
                  href={`mailto:${speakerEmail}?subject=${subject}&body=${encodeURI(renderTemplate())}`}
                  title={t("schedules.weekend.speaker.send-email-reminder")}
                >
                  <EnvelopeFill className="me-1" size={18} />
                  <span>{t("userinfo.email")}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </>
          )}
        </Dropdown>
      );
    }

    const getSpeaker2 = (): Speaker | undefined => {
      if (!s.speaker2) return;
      const s2user = usersQuery.data?.find((u) => u.id === s.speaker2);
      if (!s2user) return;
      return {
        appt: s2user.appt ?? null,
        cellphone: s2user.cellphone ?? null,
        congregation: s.speaker.congregation,
        congregation_name: s.speaker.congregation_name,
        descriptor: s2user.descriptor,
        email: s2user.email ?? null,
        homephone: s2user.homephone ?? null,
        otherphone: s2user.otherphone ?? null,
        out: false,
        public_talks: s.public_talk ? [s.public_talk] : [],
        userId: s2user.id,
        id: s2user.id,
        firstname: s2user.firstname,
        middlename: s2user.middlename ?? null,
        lastname: s2user.lastname,
        suffix: s2user.suffix ?? null,
        languageGroupId: s2user.language_group_id,
      };
    };
    const speaker2 = getSpeaker2();

    const defaultOutput =
      s.speaker?.id || s.talk_mod ? (
        <>
          <div className="d-flex flex-column">
            <SpeakerInfo schedule={s} />
            {!!speaker2 && <SpeakerInfo schedule={s} speaker2={speaker2} />}
          </div>
          <p className="text-muted">
            {s.speaker.congregation_name
              ? s.speaker.congregation_name
              : s.speaker?.id || s.talk_mod === TalkMod.Other
                ? CongName(s.speaker?.congregation, ctx, congSettingsQuery.data)
                : s.temp_cong?.id && s.talk_mod === TalkMod.TBD
                  ? CongName(s.temp_cong, ctx, congSettingsQuery.data)
                  : s.talk_mod === TalkMod.Stream
                    ? t("schedules.stream")
                    : ""}
          </p>
          <PrintTalk wmschedule={s} />
        </>
      ) : (
        <p className="mb-3">{t("schedules.weekend.no-talk-scheduled")}</p>
      );

    switch (s.talk_mod) {
      case TalkMod.TBD:
        if (s.talk_mod === TalkMod.TBD && !s.temp_cong) {
          week = (
            <>
              <h4 className="fw-bold me-2">{t("schedules.weekend.tbd")}</h4>
              <PrintTalk wmschedule={s} />
            </>
          );
        } else {
          week = defaultOutput;
        }
        break;

      case TalkMod.Stream:
        week = (
          <>
            <h4 className="fw-bold me-2">{t("schedules.stream")}</h4>
            <PrintTalk wmschedule={s} />
          </>
        );
        break;

      default:
        week = defaultOutput;
        break;
    }

    return (
      <Card key={`week${i}`}>
        <Card.Body className="d-flex flex-row gap-4">
          <div className="d-flex flex-column align-content-center">
            <Card className="calendar-day">
              <Card.Header className="bg-primary text-white text-center text-uppercase fw-bold py-1 px-4">
                {Month.fromDateString(wmDate(s.date, workingCong, eventsQuery.data)).toLocaleMonthName(i18n.language)}
              </Card.Header>
              <Card.Body className="py-1 px-4 text-center">
                <span className="fs-1">{stringToDate(wmDate(s.date, workingCong, eventsQuery.data)).getDate()}</span>
              </Card.Body>
            </Card>
            <EventBadge context="wm" event={s.event} className="ms-1" />
          </div>
          <div className="d-flex flex-wrap align-self-start flex-grow-1">
            <EventAlert context="wm" event={s.event} />
            {editMode === `week${i}` ? (
              <EditWeek
                schedule={s}
                langGroupId={props.langGroupId}
                refetchFunc={scheduleQuery.refetch}
                setEditMode={setEditMode}
                notifications={notificationsQuery.data}
                notificationDates={notificationDates}
                absences={absenceQuery.data}
                events={eventsQuery.data}
              />
            ) : (
              <div className="col-12">{week}</div>
            )}
            <Button
              className="py-2 d-flex align-items-center"
              variant={editMode === `week${i}` ? "primary" : "outline-primary"}
              onClick={() => {
                setEditMode(editMode === `week${i}` ? "" : `week${i}`);
              }}
              hidden={!s.public_talk?.number && s.talk_mod === TalkMod.CO}
              disabled={!canUpdateWeekendSchedules()}
            >
              {editMode === `week${i}` ? (
                <>
                  <XCircleFill className="me-1" size={18} />
                  <span>{t("general.cancel")}</span>
                </>
              ) : s.public_talk?.number ||
                !!s.speaker?.id ||
                s.talk_mod === TalkMod.TBD ||
                s.talk_mod === TalkMod.Other ||
                s.talk_mod === TalkMod.Stream ? (
                <>
                  <PencilFill className="me-1" size={18} />
                  <span>{t("general.edit")}</span>
                </>
              ) : (
                <>
                  <PlusCircleFill className="me-1" size={18} />
                  <span>{t("nav.publishers.add")}</span>
                </>
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  });

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          {canUpdateWeekendSchedules() && !isBeforeToday(props.to) && (
            <ActionsDropdown>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={sendAssignments}
                  disabled={showSpinner || (!haveNotification && !haveLocalSpeaker)}
                >
                  {t("schedules.assignment.send")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </ActionsDropdown>
          )}

          {showSpinner && <Spinner className="ms-2" size="sm" animation="border" />}
        </div>

        <MonthScheduleMenu
          date={props.month}
          setDate={props.setMonth}
          dateSaveKey={CongSettingTypes.WM}
          months={props.monthCount}
          setMonths={props.setMonthCount}
        />
        <div />
      </div>

      <div className="d-flex flex-column gap-4 mt-3">
        <SpeakerInfoModal speaker={speaker} show={showSpeakerInfoModal} setShow={setShowSpeakerInfoModal} />
        {Weeks}
      </div>

      {(haveLocalSpeaker || haveNotification) && (
        <div className="mt-4 ms-2">
          <NotificationStatusLegend />
        </div>
      )}
    </div>
  );
}

const SpeakerInfoModal = (props: { speaker: Speaker; show: boolean; setShow: (show: boolean) => void }) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.speaker-info")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped hover>
          <tbody>
            <tr key="speaker_name">
              <td>{t("general.name")}</td>
              <td>
                {`${props.speaker?.firstname}
                  ${props.speaker?.middlename ?? ""}
                  ${props.speaker?.lastname}
                  ${props.speaker?.suffix ?? ""}`}
              </td>
            </tr>
            <tr key="cell_phone" hidden={!props.speaker?.cellphone}>
              <td>{t("userinfo.cellphone")}</td>
              <td>{props.speaker?.cellphone}</td>
            </tr>
            <tr key="home_phone" hidden={!props.speaker?.homephone}>
              <td>{t("userinfo.homephone")}</td>
              <td>{props.speaker?.homephone}</td>
            </tr>
            <tr key="other_phone" hidden={!props.speaker?.otherphone}>
              <td>{t("userinfo.otherphone")}</td>
              <td>{props.speaker?.otherphone}</td>
            </tr>
            {!!props.speaker?.email && (
              <tr key="email">
                <td>{t("userinfo.email")}</td>
                <td>
                  <a href={`mailto:${props.speaker?.email}`}>{props.speaker?.email}</a>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

function PrintName(s: WMSchedule, t: TFunction, settings?: CongSettings): string {
  if (!s.speaker?.id && !s.talk_mod) {
    return "";
  }

  //if there's a talk assigned, show the speaker
  if (s.speaker?.id) {
    return nameOfUser(s.speaker, HourglassGlobals.nameFmt);
  }

  if (s.talk_mod === TalkMod.TBD && s.speaker.firstname === TalkMod.TBD) {
    return t("schedules.weekend.tbd");
  }

  if (s.talk_mod === TalkMod.Stream && s.speaker.firstname === TalkMod.Stream) {
    return t("schedules.stream");
  }

  if (s.talk_mod === TalkMod.Other) {
    return settings?.other_speaker_name || t("schedules.privileges.other");
  }

  return s.speaker.firstname;
}

const PrintTalk = (props: { wmschedule: WMSchedule }) => {
  const { t } = useTranslation();

  return (
    <h5 className="d-flex align-items-center fw-bolder mb-4">
      {(props.wmschedule.talk_mod === TalkMod.TBD &&
        props.wmschedule.public_talk?.title === TalkMod.TBD &&
        t("schedules.weekend.tbd")) || (
        <>
          {!!props.wmschedule.public_talk?.number && (
            <Badge bg="secondary" className="me-2">
              {props.wmschedule.public_talk?.number}
            </Badge>
          )}
          {!!props.wmschedule.public_talk?.title && props.wmschedule.public_talk?.title}
        </>
      )}
    </h5>
  );
};
