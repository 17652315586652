import React from "react";
import retryChunkLoader from "../../helpers/chunkLoadRetry";

export const DocTopicsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index" /* webpackChunkName "docs" */).then((m) => ({ default: m.DocTopics }))),
);
export const DocE2ELazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocE2E }))),
);
export const DocFAQLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocFAQ }))),
);
export const DocPrintingLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocPrinting }))),
);
export const DocIntroLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocIntro }))),
);
export const DocMonthlyWorkflowLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocMonthlyWorkflow }))),
);
export const DocMonthlySummaryLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocMonthlySummary }))),
);
export const DocFSGroupsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocFSGroups }))),
);
export const DocImportingLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocImporting }))),
);
export const DocMobileAppsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocMobileApps }))),
);
export const DocPubTransferLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.DocPubTransfer }))),
);
export const DocSchedulingLazy = React.lazy(() =>
  retryChunkLoader(() => import("./scheduling").then((m) => ({ default: m.DocScheduling }))),
);
export const DocTerritoriesLazy = React.lazy(() =>
  retryChunkLoader(() => import("./territories").then((m) => ({ default: m.DocTerritories }))),
);
