import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { E2E_IV, decryptObject } from "../helpers/e2e";
import { getStatusCode } from "../helpers/errors";
import { DefaultSrid } from "../helpers/geo";
import HourglassGlobals, { HGContext } from "../helpers/globals";
import { sortAndLabelAddresses } from "../query/territory";
import Address from "../types/address";
import { DeleteStatus, UpdateStatus } from "../types/scheduling/meetings";
import {
  MapPoint,
  Territory,
  TerritoryAddress,
  TerritoryAddressSortOrder,
  TerritoryCong,
  TerritoryImport,
  TerritoryMoveAddresses,
  TerritoryRecord,
  TerritoryRequest,
  UserTerritory,
} from "../types/scheduling/territory";
import { api, schedulingPath } from "./api";
import { UnknownCharacter } from "./const";
import { ImportAPI } from "./import";
import QueryKeys from "./queryKeys";

// when we encrypt map points, we just have space-separated x and y coords
function parseLocation(ls: string): MapPoint | undefined {
  const xy = ls.split(" ", 2);
  if (xy.length === 2 && xy[0] && xy[1]) {
    const x = parseFloat(xy[0]);
    const y = parseFloat(xy[1]);
    return { x: x, y: y, srid: DefaultSrid };
  }
  return;
}

class TerritoryAPI {
  base = schedulingPath("/territory");

  static async addressFromResponse(addr: any): Promise<TerritoryAddress> {
    if (HourglassGlobals.e2eKey && addr[E2E_IV]) {
      try {
        addr = await decryptObject(addr, HourglassGlobals.e2eKey);
        if (addr.location) {
          // decrypted, it will just be a string
          const locStr = addr.location as string;
          addr.location = parseLocation(locStr);
        }
      } catch (err) {
        console.error("failure to decrypt territory address", addr.id, err);
      }
      if (!addr.line1) addr.line1 = UnknownCharacter;
    }

    return addr;
  }

  async getTerritories(): Promise<Territory[]> {
    return api.get(this.base).json();
  }

  async getTerritory(id: number): Promise<Territory> {
    const url = `${this.base}/${id}`;
    return api.get(url).json();
  }

  async upsertOneTerritory(terr: Territory): Promise<Territory> {
    return api.put(this.base, { json: terr }).json();
  }

  async uploadBulkTerritories(formData: FormData): Promise<UpdateStatus> {
    const url = `${this.base}/import/xlsx`;

    try {
      await api.post(url, { body: formData }).json();
      return { Updated: true };
    } catch {
      return { Updated: false };
    }
  }

  async deleteOneTerritory(id: number): Promise<DeleteStatus> {
    const url = `${this.base}/${id}`;
    return api.delete(url).json();
  }

  async getRecords(latest_only: boolean): Promise<TerritoryRecord[]> {
    const url = `${this.base}/records`;
    return api.get(url, { searchParams: { latest_only: latest_only.toString() } }).json();
  }

  async upsertOneRecord(terr: Omit<TerritoryRecord, "congregation_id">): Promise<TerritoryRecord> {
    const url = `${this.base}/records`;
    return api.put(url, { json: terr }).json();
  }

  async deleteOneRecord(id: number): Promise<DeleteStatus> {
    const url = `${this.base}/records/${id}`;
    return api.delete(url).json();
  }

  async sendReminders(ids: number[]) {
    const url = `${this.base}/records/reminders`;
    await api.post(url, { json: ids });
  }

  async getRequests(): Promise<TerritoryRequest[]> {
    const url = `${this.base}/requests`;
    return api.get(url).json();
  }

  async getMyTerritories(): Promise<UserTerritory> {
    const url = `${this.base}/my/territories`;
    return api.get(url).json();
  }

  async getMyRequest(): Promise<TerritoryRequest> {
    const url = `${this.base}/my/request`;
    return api.get(url).json();
  }

  async deleteRequest(id: number): Promise<DeleteStatus> {
    const url = `${this.base}/my/request/${id}`;
    return api.delete(url).json();
  }

  async getAddresses(territoryId: number): Promise<TerritoryAddress[]> {
    const url = `${this.base}/addresses/${territoryId}`;
    const data = await api.get(url).json<any>();
    const addresses = await Promise.all(data.map((a: TerritoryAddress) => TerritoryAPI.addressFromResponse(a)));
    return sortAndLabelAddresses(addresses);
  }

  async saveAddress(addr: TerritoryAddress): Promise<TerritoryAddress> {
    if (!addr.territoryId) throw new Error("territory addresses require a territory ID");
    const url = `${this.base}/addresses`;

    const data = await api.put(url, { json: addr }).json();
    return await TerritoryAPI.addressFromResponse(data);
  }

  async updateAddresses(territoryId: number, sortOrder: TerritoryAddressSortOrder) {
    const url = `${this.base}/${territoryId}/addressSortOrder`;
    await api.post(url, { json: Object.fromEntries(sortOrder) });
  }

  async deleteAddress(addressId: number) {
    const url = `${this.base}/addresses/${addressId}`;
    await api.delete(url);
  }

  async deleteTerritoryAddresses(territoryId: number) {
    const url = `${this.base}/addresses/all/${territoryId}`;
    await api.delete(url);
  }

  async resetAddressStatus(territoryId: number): Promise<TerritoryAddress[]> {
    const url = `${this.base}/addresses/status/${territoryId}`;
    const data = await api.delete(url).json<any>();
    const addresses = await Promise.all(data.map((a: TerritoryAddress) => TerritoryAPI.addressFromResponse(a)));
    return sortAndLabelAddresses(addresses);
  }

  async getAllAddresses(): Promise<TerritoryAddress[]> {
    const url = `${this.base}/addresses/all`;
    return await api.get(url).json();
  }

  async locateAddress(address: string): Promise<TerritoryAddress> {
    return api.get(`${this.base}/geo/point`, { searchParams: { address: address } }).json();
  }

  async searchAddress(address: string): Promise<TerritoryAddress[]> {
    return api.get(`${this.base}/addresses/search`, { searchParams: { address: address } }).json();
  }

  async moveAddresses(moveAddrs: TerritoryMoveAddresses) {
    const url = `${this.base}/addresses/move`;
    await api.put(url, { json: moveAddrs });
  }

  async setCongTerritory(congTerr: TerritoryCong): Promise<TerritoryCong> {
    const url = `${this.base}/cong/geojson`;
    return api.put(url, { json: congTerr }).json();
  }

  async getCongTerritory(): Promise<TerritoryCong> {
    const url = `${this.base}/cong/geojson`;
    return api.get(url).json();
  }

  async getAddressFromPoint(lat: number, lng: number): Promise<Address> {
    const url = `${this.base}/geo/address`;
    return api.get(url, { searchParams: { lat: lat, lng: lng } }).json();
  }

  async importNative(input: TerritoryImport) {
    const url = `${this.base}/import/native`;
    return api.post(url, { json: input, timeout: ImportAPI.importTimeout }).json();
  }
}

export function useCongTerritory(options?: Partial<UseQueryOptions<TerritoryCong, Error>>) {
  const ctx = useContext(HGContext);

  const resp = useQuery({
    queryKey: [QueryKeys.TerritoryCong],
    queryFn: () => territoryApi.getCongTerritory(),
    ...options,

    // this changes so infrequently that we can cache it for the duration of a session
    gcTime: Infinity,
    staleTime: Infinity,
    enabled: !ctx.globals.missingCongTerritory,

    retry: (failureCount, err) => {
      switch (getStatusCode(err)) {
        case 404:
          ctx.setGlobals({ ...ctx.globals, missingCongTerritory: true });
          return false;
      }
      return failureCount < 2;
    },
  });

  if (resp.isSuccess && resp.data && ctx.globals.missingCongTerritory) {
    ctx.setGlobals({ ...ctx.globals, missingCongTerritory: false });
  }

  return resp;
}

export function useTerritoryRecords(latestOnly: boolean, options?: UseQueryOptions<TerritoryRecord[], Error>) {
  return useQuery({
    queryKey: [latestOnly ? QueryKeys.TerritoryRecordsLatest : QueryKeys.TerritoryRecordsAll],
    queryFn: () => territoryApi.getRecords(latestOnly),
    ...options,
  });
}

export function useTerritories(options?: UseQueryOptions<Territory[], Error>) {
  return useQuery({
    queryKey: [QueryKeys.Territories],
    queryFn: () => territoryApi.getTerritories(),
    ...options,
  });
}

export function useTerritory(territoryId: number, options?: Partial<UseQueryOptions<Territory, Error>>) {
  return useQuery({
    queryKey: [QueryKeys.Territory, territoryId],
    queryFn: () => territoryApi.getTerritory(territoryId),
    ...options,
  });
}

export function useTerritoryAddresses(options?: UseQueryOptions<TerritoryAddress[], Error>) {
  return useQuery({
    queryKey: [QueryKeys.TerritoryAddresses],
    queryFn: () => territoryApi.getAllAddresses(),
    ...options,
  });
}

export const territoryApi = new TerritoryAPI();
