import HourglassGlobals from "./globals";
import i18n from "i18next";
import { Cong } from "../types/cong";
import { CongSettings } from "../types/scheduling/settings";

export function languageGroupName(langGroupId: number, congSettings?: CongSettings): string {
  if (!langGroupId || langGroupId === HourglassGlobals.cong!.id)
    return congSettings?.congregation_display_name || HourglassGlobals.cong!.name;
  return HourglassGlobals.language_groups.find((lg) => lg.id === langGroupId)?.name ?? "?";
}

// myCongLanguageGroupName returns either the name of the congregation's language group (if there is only one),
// or the translated text for "Language Groups" if there is more than one, or the name of the congregation (if there is
// no language group).
export function myCongLanguageGroupName(): string {
  switch (HourglassGlobals.language_groups.length) {
    case 0:
      return HourglassGlobals.cong!.name;
    case 1:
      if (HourglassGlobals.language_groups[0]) {
        return HourglassGlobals.language_groups[0].name;
      } else {
        return "?";
      }
    default:
      return i18n.t("schedules.language-groups.title");
  }
}

// selectedCong finds the supplied congId in both the auth cong and any language groups, and returns it
export function selectedCong(congId: number): Cong | undefined {
  return [HourglassGlobals.cong!, ...HourglassGlobals.language_groups].find((c) => c.id === congId);
}

const SavedLangGroupId = "hg_lgroup_id";

export function storeLangGroupId(lgroupId: number) {
  try {
    if (!window.localStorage) return;
    if (!lgroupId || lgroupId < 0) {
      window.localStorage.removeItem(SavedLangGroupId);
      return;
    }
    const myCongId = HourglassGlobals.cong!.id;
    // save it as "congId:lgroupId" so we can ensure that it's for my congregation
    window.localStorage.setItem(SavedLangGroupId, `${myCongId}:${lgroupId}`);
  } catch {
    //noop
  }
}

export function getStoredLangGroupId(): number {
  try {
    const myCongId = HourglassGlobals.cong!.id;
    if (!window.localStorage) return myCongId;
    const value = window.localStorage.getItem(SavedLangGroupId);
    if (!value) return myCongId;

    const parts = value.split(":", 2);
    if (parts.length !== 2) return myCongId;
    const parsedCongId = Number(parts[0]);
    if (!parsedCongId || parsedCongId !== myCongId) return myCongId;
    const parsedLgroupId = Number(parts[1]);
    return parsedLgroupId ? parsedLgroupId : myCongId;
  } catch (_e) {
    return 0;
  }
}
