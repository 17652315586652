import classNames from "classnames";
import { FormEvent, useContext, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Container, Form, ListGroup, ListGroupItem, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import HourglassGlobals, { HGContext, PythonI18nInterpolation } from "../helpers/globals";
import { DelegateUser } from "../types/whoami";
import { nameOfUser, userCompare } from "../helpers/user";
import User from "../types/user";
import Report from "../types/report";
import { reportApi, useReports } from "../api/reports";
import { localizedMonthYearYYYYMM, Month, YYYYmm } from "../helpers/dateHelpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { parseHours } from "../helpers/report";
import { shallowEqualObjects } from "../helpers/util";
import { updateReportsCache } from "../query/report";
import { SaveResult, SaveStatus } from "./saveStatus";
import { validateMinutes } from "./publishers/reportList";
import { ChevronLeft } from "react-bootstrap-icons";
import { Warning } from "./alerts";
import { PioneerButton } from "./reports/common";
import { HGBugsnagNotify } from "../helpers/bugsnag";

enum ScreenSections {
  Users = "Users",
  Months = "Months",
  Report = "Report",
}

type ShowClasses = {
  users: string;
  months: string;
  report: string;
};

//this is the component used when someone who has minimal permissions is entering their own report
export default function ReportEntry() {
  const ctx = useContext(HGContext);
  const getUsers = (): (DelegateUser | User)[] => {
    const users = [...(ctx.globals.delegateFor || []), ctx.globals.authUser];
    return users.sort(userCompare);
  };

  const sortedUsers = getUsers();
  const initialShowSection = sortedUsers.length > 1 ? ScreenSections.Users : ScreenSections.Months;
  const [currentSection, setCurrentSection] = useState(initialShowSection);
  const [selectedUserId, setSelectedUserId] = useState(ctx.globals.authUser.id);

  const show: ShowClasses = {
    users: classNames({
      "d-none": currentSection !== ScreenSections.Users,
      "d-lg-block": true,
      "mt-4": true,
    }),
    months: classNames({
      "d-none": currentSection !== ScreenSections.Months,
      "d-lg-block": currentSection === ScreenSections.Months || currentSection === ScreenSections.Report,
      "mt-4": true,
    }),
    report: classNames({
      "d-none": currentSection !== ScreenSections.Report,
      "d-lg-block": currentSection === ScreenSections.Report,
    }),
  };

  return (
    <Container>
      <Row>
        {sortedUsers.length > 1 && (
          <Col className={show.users} lg={4}>
            <ListGroup>
              {sortedUsers.map((du: DelegateUser) => (
                <ListGroupItem
                  key={du.id}
                  eventKey={du.id}
                  action
                  onClick={() => {
                    setCurrentSection(ScreenSections.Months);
                    setSelectedUserId(du.id);
                  }}
                >
                  {nameOfUser(du, HourglassGlobals.nameFmt)}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        )}
        <ReportEntryMonthList
          userId={selectedUserId}
          users={sortedUsers}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          show={show}
        />
      </Row>
    </Container>
  );
}

type ReportEntryMonthListProps = {
  userId: number;
  users: (User | DelegateUser)[];
  currentSection: string;
  setCurrentSection: (section: ScreenSections) => void;
  show: ShowClasses;
};

const oct2023 = new Month(2023, 10);

function ReportEntryMonthList(props: ReportEntryMonthListProps) {
  const { i18n } = useTranslation();
  const reportsQuery = useReports(props.userId);
  const [selectedReport, setSelectedReport] = useState<Report>();
  const user = props.users.find((u) => u.id === props.userId);

  if (!user) return null;

  const is1023Format = (): boolean => {
    if (!selectedReport) return false;
    const rptMonth = new Month(selectedReport.year, selectedReport.month);
    return !rptMonth.before(oct2023);
  };

  return (
    <>
      <Col className={props.show.months} lg={4}>
        <ListGroup className="d-lg-none d-xl-none mb-4">
          {props.users.length > 1 && (
            <ListGroupItem action onClick={() => props.setCurrentSection(ScreenSections.Users)}>
              <ChevronLeft /> {user.displayName}
            </ListGroupItem>
          )}
        </ListGroup>
        <ListGroup>
          {reportsQuery.data &&
            reportsQuery.data.map((rpt: Report) => (
              <ListGroupItem
                key={`${props.userId}_${YYYYmm(rpt.year, rpt.month)}`}
                className="d-flex justify-content-between"
                eventKey={`${props.userId}_${YYYYmm(rpt.year, rpt.month)}`}
                action
                color={rpt.submitted_month ? "#6c757d" : ""}
                onClick={() => {
                  props.setCurrentSection(ScreenSections.Report);
                  setSelectedReport(rpt);
                }}
              >
                {localizedMonthYearYYYYMM(rpt.year, rpt.month, i18n.language)}
                <PioneerButton pioneer={rpt.pioneer} />
              </ListGroupItem>
            ))}
        </ListGroup>
      </Col>
      {props.currentSection === ScreenSections.Report && selectedReport && !is1023Format() && (
        <ReportEntryMonth
          key={`${props.userId}_${YYYYmm(selectedReport.year, selectedReport.month)}`}
          userId={props.userId}
          userDisplayName={user.displayName}
          totalUsers={props.users.length}
          report={selectedReport}
          show={props.show}
          setCurrentSection={props.setCurrentSection}
        />
      )}
      {props.currentSection === ScreenSections.Report && selectedReport && is1023Format() && (
        <ReportEntryMonth1023
          key={`${props.userId}_${YYYYmm(selectedReport.year, selectedReport.month)}`}
          userId={props.userId}
          userDisplayName={user.displayName}
          totalUsers={props.users.length}
          report={selectedReport}
          show={props.show}
          setCurrentSection={props.setCurrentSection}
        />
      )}
    </>
  );
}

type ReportEntryMonthProps = {
  userId: number;
  userDisplayName: string;
  totalUsers: number;
  report: Report;
  show: ShowClasses;
  setCurrentSection: (section: ScreenSections) => void;
};

function ReportEntryMonth(props: ReportEntryMonthProps) {
  const { t, i18n } = useTranslation();
  const reportSaveMutation = useMutation({ mutationFn: (report: Report) => reportApi.save(report) });
  const queryClient = useQueryClient();

  const [report, setReport] = useState<Report>(props.report);
  const [isDirty, setIsDirty] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [saveResult, setSaveResult] = useState(SaveResult.None);
  const hoursRef = useRef<HTMLInputElement>(null);
  const returnVisitsRef = useRef<HTMLInputElement>(null);

  const rptChange = (key: keyof Report) => {
    return (e: any) => {
      const inputVal: string = e.currentTarget.value;
      let value: string | number | null;
      if (key === "remarks") {
        value = inputVal !== "" ? inputVal : null;
      } else {
        if (key === "minutes") {
          value = parseHours(inputVal);
          hoursRef.current?.setCustomValidity("");
        } else {
          value = parseInt(inputVal);
          if (!value || isNaN(value)) value = null;
        }
      }

      if (key === "studies" || key === "returnvisits") returnVisitsRef.current?.setCustomValidity("");

      updateReport(key, value);
    };
  };

  const updateReport = (key: keyof Report, value: string | number | null) => {
    const newReport = { ...report, [key]: value };
    if (!shallowEqualObjects(report, newReport)) {
      setReport(newReport);
      setIsDirty(true);
    }
  };

  const saveReport = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateMinutes(report.minutes)) {
      hoursRef.current?.setCustomValidity(t("popup.error.title.invalid-input"));
    }
    if (report.studies && report.returnvisits && report.studies > report.returnvisits) {
      returnVisitsRef.current?.setCustomValidity(t("popup.error.body.studies-rvs"));
    }

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    setSaveInProgress(true);
    try {
      const savedReport = await reportSaveMutation.mutateAsync(report);
      setReport(savedReport);
      updateReportsCache(queryClient, report, savedReport);
      setSaveResult(SaveResult.Success);
      setIsDirty(false);
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      console.error("save report error (reportEntry)", err);
      HGBugsnagNotify("saveReport", err);
    } finally {
      setSaveInProgress(false);
    }
  };

  const submitted = !!report.submitted_month;

  const isCurrentMonth = (): boolean => {
    const now = new Date();
    return report.month === now.getMonth() + 1 && report.year === now.getFullYear() && now.getDate() < 28;
  };

  return (
    <Col className={props.show.report}>
      <ListGroup className="d-lg-none d-xl-none mt-4">
        {props.totalUsers > 1 && (
          <ListGroupItem action onClick={() => props.setCurrentSection(ScreenSections.Users)}>
            <ChevronLeft /> {props.userDisplayName}
          </ListGroupItem>
        )}
        <ListGroupItem action onClick={() => props.setCurrentSection(ScreenSections.Months)}>
          <ChevronLeft /> {localizedMonthYearYYYYMM(props.report.year, props.report.month, i18n.language)}
        </ListGroupItem>
      </ListGroup>
      <Form onSubmit={saveReport}>
        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="placements">
              <Form.Label>{t("report16.placements")}</Form.Label>
              <Form.Control
                type="number"
                placeholder="."
                disabled={submitted}
                value={report.placements || ""}
                onChange={rptChange("placements")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="videoshowings">
              <Form.Label>{t("report16.videoshowings")}</Form.Label>
              <Form.Control
                type="number"
                placeholder="."
                disabled={submitted}
                value={report.videoshowings || ""}
                onChange={rptChange("videoshowings")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="hours">
              <Form.Label>{t("report.hours")}</Form.Label>
              {/*handle minutes with a toggle switch or similar ?*/}
              <Form.Control
                required
                ref={hoursRef}
                type="number"
                placeholder="."
                disabled={submitted}
                value={report.minutes !== undefined && report.minutes !== null ? report.minutes / 60 || 0 : ""}
                onChange={rptChange("minutes")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="returnvisits">
              <Form.Label>{t("report.returnvisits")}</Form.Label>
              <Form.Control
                type="number"
                ref={returnVisitsRef}
                placeholder="."
                disabled={submitted}
                value={report.returnvisits || ""}
                onChange={rptChange("returnvisits")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="studies">
              <Form.Label>{t("report16.studies")}</Form.Label>
              <Form.Control
                type="number"
                placeholder="."
                disabled={submitted}
                value={report.studies || ""}
                onChange={rptChange("studies")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="remarks">
              <Form.Label>{t("report16.remarks")}</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="."
                disabled={submitted}
                style={{ height: "100px" }}
                value={report.remarks || ""}
                onChange={rptChange("remarks")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm className="d-flex align-items-center">
            <Button
              className="me-2"
              type="submit"
              variant="primary"
              size="lg"
              disabled={!isDirty || saveInProgress || submitted}
            >
              {t("report.send")}
            </Button>
            {saveInProgress && <Spinner animation="border" />}
            <SaveStatus saveKey={1} saveResult={saveResult} setSaveResult={setSaveResult} />
          </Col>
        </Row>
        {isCurrentMonth() && !report.id && (
          <Row className="mt-4">
            <Col xs="auto">
              <Warning
                text={t("pubweb.confirm.future-month %(month)s", {
                  interpolation: PythonI18nInterpolation,
                  month: localizedMonthYearYYYYMM(report.year, report.month, i18n.language),
                })}
              />
            </Col>
          </Row>
        )}
      </Form>
    </Col>
  );
}

function ReportEntryMonth1023(props: ReportEntryMonthProps) {
  const { t, i18n } = useTranslation();
  const reportSaveMutation = useMutation({ mutationFn: (report: Report) => reportApi.save(report) });
  const queryClient = useQueryClient();

  const [report, setReport] = useState<Report>(props.report);
  const [isDirty, setIsDirty] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [saveResult, setSaveResult] = useState(SaveResult.None);
  const hoursRef = useRef<HTMLInputElement>(null);

  const rptChange = (key: keyof Report) => {
    return (e: any) => {
      const inputVal: string = e.currentTarget.value;
      let value: string | number | null;
      if (key === "remarks") {
        value = inputVal !== "" ? inputVal : null;
      } else {
        if (key === "minutes") {
          value = parseHours(inputVal);
          hoursRef.current?.setCustomValidity("");
        } else {
          value = parseInt(inputVal);
          if (!value || isNaN(value)) value = null;
        }
      }

      updateReport(key, value);
    };
  };

  const updateReport = (key: keyof Report, value: string | number | null) => {
    const newReport = { ...report, [key]: value };
    if (!shallowEqualObjects(report, newReport)) {
      setReport(newReport);
      setIsDirty(true);
    }
  };

  const saveReport = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateMinutes(report.minutes)) {
      hoursRef.current?.setCustomValidity(t("popup.error.title.invalid-input"));
    }

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    setSaveInProgress(true);
    try {
      const savedReport = await reportSaveMutation.mutateAsync(report);
      setReport(savedReport);
      updateReportsCache(queryClient, report, savedReport);
      setSaveResult(SaveResult.Success);
      setIsDirty(false);
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      console.error("save report error (reportEntry)", err);
      HGBugsnagNotify("reportEntry", err);
    } finally {
      setSaveInProgress(false);
    }
  };

  const submitted = !!report.submitted_month;

  const isCurrentMonth = (): boolean => {
    const now = new Date();
    return report.month === now.getMonth() + 1 && report.year === now.getFullYear() && now.getDate() < 28;
  };

  const toStringHours = (): string => {
    const num = report.minutes;
    if (!num || isNaN(num)) return "";
    if (num === 1) return "0.02";

    const hours = num / 60;
    //don't need decimals on large numbers
    if (hours >= 100) Math.round(hours).toString();
    if (num % 60 !== 0) return hours.toFixed(2);
    return hours.toString();
  };

  return (
    <Col className={props.show.report}>
      <ListGroup className="d-lg-none d-xl-none mt-4">
        {props.totalUsers > 1 && (
          <ListGroupItem action onClick={() => props.setCurrentSection(ScreenSections.Users)}>
            <ChevronLeft /> {props.userDisplayName}
          </ListGroupItem>
        )}
        <ListGroupItem action onClick={() => props.setCurrentSection(ScreenSections.Months)}>
          <ChevronLeft /> {localizedMonthYearYYYYMM(props.report.year, props.report.month, i18n.language)}
        </ListGroupItem>
      </ListGroup>
      <Form onSubmit={saveReport}>
        {!report.pioneer && (
          <Row className="mt-4">
            <Col sm>
              <Form.Group className="mb-4" controlId="participated">
                <Form.Label>{t("report.participated")}</Form.Label>
                <div>
                  <ButtonGroup>
                    <Button
                      className="px-1 py-1 disabled-full-color"
                      onClick={() => updateReport("minutes", 1)}
                      disabled={submitted}
                      variant={report.minutes ?? 0 > 0 ? "info" : "outline-secondary"}
                    >
                      {t("popup.confirm.button.yes")}
                    </Button>
                    <Button
                      className="px-1 py-1 disabled-full-color"
                      disabled={submitted}
                      onClick={() => updateReport("minutes", 0)}
                      variant={report.minutes === 0 ? "info" : "outline-secondary"}
                    >
                      {t("popup.confirm.button.no")}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
        {!!report.pioneer && (
          <Row className="mt-4">
            <Col sm>
              <Form.Group className="mb-4" controlId="hours">
                <Form.Label>{t("report.hours")}</Form.Label>
                <Form.Control
                  required
                  ref={hoursRef}
                  type="number"
                  disabled={submitted}
                  value={toStringHours()}
                  onChange={rptChange("minutes")}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="studies">
              <Form.Label>{t("report16.studies")}</Form.Label>
              <Form.Control
                type="number"
                disabled={submitted}
                value={report.studies || ""}
                onChange={rptChange("studies")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm>
            <Form.Group className="mb-4" controlId="remarks">
              <Form.Label>{t("report16.remarks")}</Form.Label>
              <Form.Control
                as="textarea"
                disabled={submitted}
                style={{ height: "100px" }}
                value={report.remarks || ""}
                onChange={rptChange("remarks")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm className="d-flex align-items-center">
            <Button
              className="me-2"
              type="submit"
              variant="primary"
              size="lg"
              disabled={!isDirty || saveInProgress || submitted}
            >
              {t("report.send")}
            </Button>
            {saveInProgress && <Spinner animation="border" />}
            <SaveStatus saveKey={1} saveResult={saveResult} setSaveResult={setSaveResult} />
          </Col>
        </Row>
        {isCurrentMonth() && !report.id && (
          <Row className="mt-4">
            <Col xs="auto">
              <Warning
                text={t("pubweb.confirm.future-month %(month)s", {
                  interpolation: PythonI18nInterpolation,
                  month: localizedMonthYearYYYYMM(report.year, report.month, i18n.language),
                })}
              />
            </Col>
          </Row>
        )}
      </Form>
    </Col>
  );
}
