import { useContext } from "react";
import { useShepherd } from "react-shepherd";
import ShepherdBase, { TourOptions } from "shepherd.js";
import { t } from "../helpers/locale";
import { HGNavigate } from "../helpers/navigate";
import { HGContext } from "../helpers/globals";
import { offset } from "@floating-ui/dom";

class tourStatus {
  static hasLaunched = false;

  static launched() {
    tourStatus.hasLaunched = true;
  }
}

export function HourglassTour() {
  const Shepherd = useShepherd();
  const ctx = useContext(HGContext);
  const tour = new Shepherd.Tour({
    ...tourOptions,
  });
  tour.addSteps(getTourSteps(tour, ctx.globals.authUser.id));

  if (tour && !tourStatus.hasLaunched && ctx.globals.flags.tour) {
    tourStatus.launched();
    tour.start().then();
  }
  return null;
}

function pubDetailsText(): string {
  return `<p>${t("tour.pubdetails.intro")}</p>
    <p>${t("tour.pubdetails.invitations")} <b class="ms-1 me-1">  ${t("tour.pubdetails.invitations-security")}</b> ${t(
      "tour.pubdetails.invitations-multiple",
    )} </p>`;
}

function pubMenuText(): string {
  return `<ul>
    <li><b>${t("nav.publishers.add")}</b> ${t("tour.pubmenu.add")}</li>
    <li><b>${t("nav.publishers.send-reminders")}</b> ${t("tour.pubmenu.send-reminders")}</li>
    <li><b>${t("nav.publishers.record-cards")}</b> ${t("tour.pubmenu.record-cards")}</li>
    <li><b>${t("nav.publishers.not-publishing")}</b> ${t("tour.pubmenu.not-publishing")}</li>
    <li><b>${t("nav.publishers.transfer")}</b> ${t("docs.pubtransfer.1")}</li>
    <li><b>${t("nav.publishers.contacts.all-publishers")}</b> ${t("tour.pubmenu.all-publishers")}</li>
    <li><b>${t("nav.publishers.contacts.emergency")}</b> ${t("tour.pubmenu.emergency")}</li>
    </ul>`;
}

function reportsMenuText(): string {
  return `<ul>
  <li><b>${t("chart.legend.missing")}</b> ${t("tour.reportsmenu.missing")}</li>
  <li><b>${t("nav.reports.monthly.summary")}</b> ${t("tour.reportsmenu.monthly.summary")}</li>
  <li><b>${t("nav.reports.monthly.detail")}</b> ${t("tour.reportsmenu.monthly.detail")}</li>
  <li><b>${t("list.congregation.title")}</b> ${t("tour.reportsmenu.averages.congregation")}</li>
  <li><b>${t("nav.reports.averages.group")}</b> ${t("tour.reportsmenu.averages.group")}</li>
  <li><b>${t("list.publishers.title")}</b> ${t("tour.reportsmenu.averages.publisher")}</li>
  <li><b>${t("recordcard.svcyeartotals.regular-pioneers")}</b> ${t("tour.reportsmenu.pioneers")}</li>
  <li><b>${t("nav.reports.cong-analysis")}</b> ${t("tour.reportsmenu.cong-analysis")}</li>
  <li><b>${t("nav.reports.svcyeartotals")}</b> ${t("tour.reportsmenu.svcyeartotals")}</li>
</ul>`;
}

function schedulingMenuText(): string {
  return `<ul>
  <li><b>${t("conganalysis.attendance.midweek")}</b> ${t("www.features.scheduling.midweek")}</li>
  <li>
    <b>${t("conganalysis.attendance.weekend")}</b>
    ${t("www.features.scheduling.weekend")} <br/>
    ${t("www.features.scheduling.public-talks")}
  </li>
  <li><b>${t("schedules.ava.title")}</b> ${t("www.features.scheduling.ava")}</li>
  <li><b>${t("schedules.cleaning.title")}</b>/<b>${t("schedules.field-service.title")}</b> ${t(
    "www.features.scheduling.fs-cleaning",
  )}</li>
  <li><b>${t("schedules.public-witnessing.title")}</b> ${t("www.features.scheduling.public-witnessing")}</li>
  <li><b>${t("schedules.privileges.title")}</b> ${t("www.features.scheduling.privileges")}</li>
  <li><b>${t("schedules.absence.title")}</b> ${t("www.features.scheduling.absences")}</li>
  <li><b>${t("schedules.events.title")}</b>
    <ul>
      <li>${t("schedules.events.co-visit")}</li>
      <li>${t("schedules.events.circuit-assembly")}</li>
      <li>${t("schedules.events.regional-convention")}</li>
      <li>${t("schedules.events.memorial")}</li>
    </ul>
  </li>
</ul>`;
}

function congMenuText(): string {
  return `<ul>
  <li><b>${t("nav.congregation.fsgroups")}</b> ${t("tour.congmenu.fsgroups")}</li>
  <li><b>${t("nav.congregation.attendance")}</b> ${t("tour.congmenu.attendance")}</li>
  <li><b>${t("nav.congregation.profile-permissions")}</b> ${t("tour.congmenu.profile-permissions")}</li>
  <li><b>${t("nav.congregation.import")}</b> ${t("tour.congmenu.import")}</li>
  <li><b>${t("nav.congregation.export")}</b> ${t("tour.congmenu.export")}</li>
</ul>`;
}

export function getTourSteps(tour: ShepherdBase.Tour, userId: number): ShepherdBase.StepOptions[] {
  const cancelBut = {
    text: t("tour.button.exit"),
    action: tour.cancel,
  };

  const nextBut = {
    text: t("tour.button.next"),
    action: tour.next,
  };

  const backBut = {
    text: t("general.go-back"),
    action: tour.back,
  };

  const youtubeIntroIFrame =
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/WAbQKpN7zrM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

  return [
    {
      id: "main",
      title: t("tour.title"),
      text: t("tour.welcome"),
      buttons: [nextBut],
    },
    {
      id: "user-search",
      attachTo: { element: "#user-search", on: "bottom" },
      title: t("list.publishers.title"),
      text: "<p>" + t("tour.userlist.main") + "</p>",
      buttons: [nextBut],
    },
    {
      id: "user-details",
      attachTo: { element: "#publisher-summary-card", on: "bottom" },
      title: t("tour.pubdetails.title"),
      text: pubDetailsText(),
      beforeShowPromise: () => {
        return new Promise((resolve) => {
          HGNavigate.navigateInstance && HGNavigate.navigateInstance(`/user/${userId}`);
          setTimeout(() => {
            resolve(0);
          }, 500);
        });
      },
      buttons: [backBut, nextBut],
    },
    {
      id: "reports",
      attachTo: { element: "#reports-list-table", on: "top" },
      title: t("nav.reports.0"),
      text: t("tour.reports.main"),
      buttons: [backBut, nextBut],
    },
    {
      id: "pubmenu",
      attachTo: { element: "#publishers-nav", on: "right" },
      title: t("list.publishers.title"),
      text: pubMenuText(),
      beforeShowPromise: beforeShow("publishers-nav"),
      buttons: [backBut, nextBut],
    },
    {
      id: "reportsmenu",
      attachTo: { element: "#reports-nav", on: "right" },
      title: t("nav.reports.0"),
      text: reportsMenuText(),
      beforeShowPromise: beforeShow("reports-nav"),
      buttons: [backBut, nextBut],
    },
    {
      id: "schedmenu",
      attachTo: { element: "#scheduling-nav", on: "right" },
      title: t("schedules.scheduling"),
      text: schedulingMenuText(),
      beforeShowPromise: beforeShow("scheduling-nav"),
      buttons: [backBut, nextBut],
    },
    {
      id: "congmenu",
      attachTo: { element: "#cong-nav", on: "right" },
      title: t("list.congregation.title"),
      text: congMenuText(),
      beforeShowPromise: beforeShow("cong-nav"),
      buttons: [backBut, nextBut],
    },
    {
      id: "tour-end",
      title: t("tour.title"),
      classes: "tour-end",
      text: `<p class="mb-4">${t("tour.finished")}</p>` + youtubeIntroIFrame,
      buttons: [backBut, cancelBut],
    },
  ];
}

function beforeShow(element: string): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve) => {
      const but = document.querySelector(`#${element} button`) as HTMLButtonElement;
      but?.click();
      setTimeout(() => {
        resolve(0);
      }, 200);
    });
  };
}

export const tourOptions: TourOptions = {
  defaultStepOptions: {
    classes: "hourglass-shepherd",
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 0, crossAxis: 12 })],
    },
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};
