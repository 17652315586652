import React from "react";
import { Cong, NameFormat } from "../types/cong";
import { ISODateString } from "../types/date";
import { Permission, PermissionGroup } from "../types/permission";
import { Flags } from "../types/startup";
import User, { blankUser } from "../types/user";
import { DelegateUser, WhoamiSettings } from "../types/whoami";
import { Month } from "./dateHelpers";

export type GlobalContext = {
  globals: HGGlobals;
  setGlobals: (newGlobals: HGGlobals) => void;
};

export type HGGlobals = {
  cong: Cong | null;
  staticUrl: string;
  nameFmt: NameFormat;
  e2eKey?: CryptoKey;
  authUser: User;
  delegateFor?: DelegateUser[];
  permissions: Set<string>;
  permissionGroups: PermissionGroup[];
  oldestReport?: string;
  reportableServiceYears: number[];
  lastSubmittedMonth?: string;
  workingMonth: ISODateString;
  flags: Flags;
  warningsDismissed: boolean;
  featureFlags: { [key: string]: string };
  featureDismissed: boolean;
  missingCongTerritory: boolean;
  language_groups: Cong[];
  labelAsPreferredName: boolean;
  whoamiSettings: WhoamiSettings;
  autoSubmitReportsBefore: Map<number, Month>;
};

export const HGContext = React.createContext<GlobalContext>({
  globals: emptyGlobals(),
  setGlobals: () => {
    return;
  },
});

export function emptyGlobals(): HGGlobals {
  return {
    cong: {
      id: 0,
      guid: "",
      name: "",
      number: 0,
      locale: { id: 0, name: "", code: "", symbol: "" },
      country: {
        id: 0,
        code: "",
        name: "",
        datefmt: "",
        addrfmt: "",
        namefmt: "",
        paper_size: "letter",
      },
      wmdow: 0,
      wmtime: "",
      mmdow: 0,
      mmtime: "",
      wmdow_future: 0,
      wmtime_future: "",
      mmdow_future: 0,
      mmtime_future: "",
      future_start_date: "",
      timezone: { id: 0, name: "", offset: "", countrycode: "" },
      jworg_skip: false,
      circuit_id: 0,
    },
    staticUrl: "",
    nameFmt: NameFormat.Last,
    authUser: blankUser(),
    permissions: new Set<string>(),
    permissionGroups: [],
    oldestReport: "",
    lastSubmittedMonth: "",
    workingMonth: "",
    reportableServiceYears: [],
    flags: {
      tour: false,
      submitWarning: false,
      deletePending: false,
      congNotFound: false,
      e2eIncomplete: false,
      feature: false,
      congNotLinked: false,
    },
    warningsDismissed: false,
    featureFlags: {},
    featureDismissed: false,
    missingCongTerritory: false,
    language_groups: [],
    labelAsPreferredName: false,
    whoamiSettings: { territory: { sortByLocality: false } },
    autoSubmitReportsBefore: new Map<number, Month>(),
  };
}

export const PythonI18nInterpolation = {
  prefix: "%(",
  suffix: ")s",
};

export const DefaultLocale: Locale = {
  id: 1,
  code: "en",
  name: "English",
  symbol: "E",
};

// Countries that have Branch direction to always have the S-21 name be in "Last, First" form
const s21LastAlwaysCountries = new Set<string>(["es"]);

//this stores things that are needed across the app and in places that are not react components
//they are usually set once at init time and rarely if ever change while the app is running
//these are here so that they can be accessed outside of a react component, as many of our methods need them
//we always must call setGlobals whenever the context is being updated
export default class HourglassGlobals {
  static cong: Cong | null;
  static staticUrl: string;
  static nameFmt: NameFormat = NameFormat.Last;
  static e2eKey?: CryptoKey;
  static authUser: User;
  static permissions = new Set<string>();
  static AdminGroupId = 1;
  static manuallySubmittedUserIds = new Set<number>();
  static oAuthRedirectURI = window.HGGlobal?.redirectUri || "http://localhost:8000/v2/oauth/redirect";
  static appleAuthRedirectURI = window.HGGlobal?.appleRedirectUri || "http://localhost:8000/v2/oauth/appleid/redirect";
  static GoogleSubCookie = "hg_googlesub";
  static AppleSubCookie = "hg_applesub";
  static AppBase = "/v2/page/app";
  static permissionGroups: PermissionGroup[];
  static oldestReport?: string;
  static reportableServiceYears: number[];
  static lastSubmittedMonth?: string;
  static workingMonth: string;
  static flags: Flags;
  static warningsDismissed: boolean;
  static featureFlags: { [key: string]: string };
  static featureDismissed: boolean;
  static delegateFor?: DelegateUser[];
  static missingCongTerritory: boolean;
  static language_groups: Cong[] = [];
  static labelAsPreferredName: boolean;
  static whoamiSettings: WhoamiSettings;
  static autoSubmitReportsBefore: Map<number, Month>;

  static isE2E(): boolean {
    return !!HourglassGlobals.e2eKey;
  }

  static setGlobals(newGlobals: HGGlobals) {
    HourglassGlobals.cong = newGlobals.cong;
    HourglassGlobals.staticUrl = newGlobals.staticUrl;
    HourglassGlobals.nameFmt = newGlobals.nameFmt;
    HourglassGlobals.e2eKey = newGlobals.e2eKey;
    HourglassGlobals.authUser = newGlobals.authUser;
    HourglassGlobals.permissions = newGlobals.permissions;
    HourglassGlobals.permissionGroups = newGlobals.permissionGroups;
    HourglassGlobals.oldestReport = newGlobals.oldestReport;
    HourglassGlobals.reportableServiceYears = newGlobals.reportableServiceYears;
    HourglassGlobals.lastSubmittedMonth = newGlobals.lastSubmittedMonth;
    HourglassGlobals.flags = newGlobals.flags;
    HourglassGlobals.workingMonth = newGlobals.workingMonth;
    HourglassGlobals.warningsDismissed = newGlobals.warningsDismissed;
    HourglassGlobals.featureFlags = newGlobals.featureFlags;
    HourglassGlobals.delegateFor = newGlobals.delegateFor;
    HourglassGlobals.featureDismissed = newGlobals.featureDismissed;
    HourglassGlobals.missingCongTerritory = newGlobals.missingCongTerritory;
    HourglassGlobals.language_groups = newGlobals.language_groups;
    HourglassGlobals.labelAsPreferredName = newGlobals.labelAsPreferredName;
    HourglassGlobals.whoamiSettings = newGlobals.whoamiSettings;
    HourglassGlobals.autoSubmitReportsBefore = newGlobals.autoSubmitReportsBefore;
  }

  static locales(): Locale[] {
    if (!window.HGGlobal?.localeData) {
      return [
        DefaultLocale,
        {
          id: 2,
          code: "es",
          name: "Spanish",
          symbol: "S",
        },
        {
          id: 19,
          code: "de",
          name: "German",
          symbol: "X",
        },
        {
          id: 9,
          code: "it",
          name: "Italian",
          symbol: "I",
        },
        {
          id: 10,
          code: "fr",
          name: "French",
          symbol: "F",
        },
        {
          id: 4,
          code: "pt-BR",
          name: "Portuguese (Brazil)",
          symbol: "T",
        },
        {
          id: 14,
          code: "th",
          name: "Thai",
          symbol: "SI",
        },
        {
          id: 35,
          code: "zh-Hant",
          name: "Traditional Chinese",
          symbol: "CH",
        },
        {
          id: 36,
          code: "ro",
          name: "Romanian",
          symbol: "M",
        },
        {
          id: 37,
          code: "nl",
          name: "Dutch",
          symbol: "D",
        },
        {
          id: 38,
          code: "hu",
          name: "Hungarian",
          symbol: "H",
        },
        {
          id: 27,
          code: "vi",
          name: "Vietnamese",
          symbol: "VT",
        },
        {
          id: 44,
          code: "af",
          name: "Afrikaans",
          symbol: "AF",
        },
        {
          id: 41,
          code: "ko",
          name: "Korean",
          symbol: "KO",
        },
        {
          id: 52,
          code: "ca-valencia",
          name: "Valencian",
          symbol: "VLC",
        },
        {
          id: 30,
          code: "vec-BR",
          name: "Talian",
          symbol: "TAL",
        },
        {
          id: 55,
          code: "hy",
          name: "Armenian",
          symbol: "REA",
        },
        {
          id: 59,
          code: "ka",
          name: "Georgian",
          symbol: "GE",
        },
        {
          id: 62,
          code: "ne",
          name: "Nepali",
          symbol: "NP",
        },
      ];
    }
    return window.HGGlobal.localeData.locales;
  }

  static countries(): Country[] {
    if (!window.HGGlobal?.localeData.countries) {
      return [
        {
          id: 1,
          code: "US",
          name: "USA",
          datefmt: "mm/dd/yyyy",
          addrfmt: "csp",
          namefmt: "last",
          paper_size: "letter",
        },
      ];
    }
    return window.HGGlobal.localeData.countries;
  }

  static timezones(): Timezone[] {
    if (!window.HGGlobal?.localeData.timezones) {
      return [
        {
          id: 1,
          countrycode: "US",
          offset: "-08:00",
          name: "US/Pacific",
        },
      ];
    }
    return window.HGGlobal.localeData.timezones.sort((a, b) => (a.offset > b.offset ? 1 : -1));
  }

  static isAdmin(): boolean {
    if (!HourglassGlobals.permissions) return false;
    return (
      HourglassGlobals.permissions.has(Permission.ManagePermissions) &&
      HourglassGlobals.permissions.has(Permission.UpdateCongregation) &&
      HourglassGlobals.permissions.has(Permission.UpdateReports) &&
      HourglassGlobals.permissions.has(Permission.UpdateUsers) &&
      HourglassGlobals.permissions.has(Permission.UpdateAttendance) &&
      HourglassGlobals.permissions.has(Permission.UpdateAddresses)
    );
  }

  static haveWarnings(): boolean {
    return (
      HourglassGlobals.flags.submitWarning ||
      HourglassGlobals.flags.deletePending ||
      HourglassGlobals.flags.congNotFound ||
      HourglassGlobals.flags.e2eIncomplete ||
      HourglassGlobals.flags.congNotLinked
    );
  }

  static getWarningsCount(): number {
    let warningCount = 0;

    if (HourglassGlobals.flags.submitWarning) {
      warningCount++;
    }
    if (HourglassGlobals.flags.deletePending) {
      warningCount++;
    }
    if (HourglassGlobals.flags.congNotFound) {
      warningCount++;
    }
    if (HourglassGlobals.flags.e2eIncomplete) {
      warningCount++;
    }
    if (HourglassGlobals.flags.congNotLinked) {
      warningCount++;
    }

    return warningCount;
  }

  static s21NameFormat(): NameFormat {
    if (s21LastAlwaysCountries.has(HourglassGlobals.cong?.country.code.toLowerCase() || "")) return NameFormat.Last;
    return HourglassGlobals.nameFmt;
  }
}
