import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { E2E_IV, FORCE_DECRYPT_HEADER, decryptObject, encryptObject } from "../helpers/e2e";
import HourglassGlobals from "../helpers/globals";
import { nameOfUser } from "../helpers/user";
import { Permission } from "../types/permission";
import User, { Delegate, UserSelfUpdate } from "../types/user";
import { api, fsReportPath } from "./api";
import QueryKeys from "./queryKeys";

export class UserAPI {
  static base = fsReportPath("/users");
  static E2EProps = new Set<keyof User>([
    "firstname",
    "middlename",
    "lastname",
    "suffix",
    "pioneerid",
    "birth",
    "baptism",
    "email",
    "cellphone",
    "homephone",
    "otherphone",
    "comments",
  ]);

  static urlFromId(id: number): string {
    return `${UserAPI.base}/${id}`;
  }

  static async userFromResponse(user: any): Promise<User> {
    if (HourglassGlobals.e2eKey) {
      //user might be encrypted but not emergency contact, or vice versa
      if (user[E2E_IV]) {
        try {
          user = await decryptObject(user, HourglassGlobals.e2eKey);
        } catch (err) {
          console.error("failure to decrypt user", user.id, err);
        }
      }
      if (Array.isArray(user.emergencycontacts) && user.emergencycontacts.length > 0) {
        //some ECs might be encrypted while others are not (e.g. during migration)
        user.emergencycontacts = await Promise.all(
          user.emergencycontacts.map(async (ec: any) => {
            if (ec[E2E_IV] && HourglassGlobals.e2eKey) {
              try {
                return await decryptObject(ec, HourglassGlobals.e2eKey);
              } catch (err) {
                console.error("failure to decrypt user emergencycontact", ec.id, err);
              }
            }
            return ec;
          }),
        );
      }
    }

    if (!user.firstname) user.firstname = "";
    if (!user.lastname) user.lastname = "";
    user.displayName = nameOfUser(user);
    return user;
  }

  async get(id: number): Promise<User> {
    const data = await api.get(UserAPI.urlFromId(id)).json();
    return await UserAPI.userFromResponse(data);
  }

  async getAuthUser(): Promise<User[]> {
    const user = await this.get(HourglassGlobals.authUser.id);
    return [user];
  }

  async getAll(): Promise<User[]> {
    const data = await api.get(UserAPI.base).json<{ users: User[] }>();
    return await Promise.all(data.users.map((u: User) => UserAPI.userFromResponse(u)));
  }

  async save(user: User, forceDecrypted = false): Promise<User> {
    const url = user.id ? UserAPI.urlFromId(user.id) : UserAPI.base;
    let savedUser: any = user;
    if (HourglassGlobals.e2eKey && !forceDecrypted) {
      savedUser = await encryptObject(user, HourglassGlobals.e2eKey, UserAPI.E2EProps);
    }

    delete savedUser.displayName;
    if (HourglassGlobals.e2eKey && forceDecrypted) {
      savedUser.e2e_iv = null;
      savedUser.e2e_firstname = null;
      savedUser.e2e_middlename = null;
      savedUser.e2e_lastname = null;
      savedUser.e2e_suffix = null;
      savedUser.e2e_pioneerid = null;
      savedUser.e2e_birth = null;
      savedUser.e2e_baptism = null;
      savedUser.e2e_email = null;
      savedUser.e2e_cellphone = null;
      savedUser.e2e_homephone = null;
      savedUser.e2e_otherphone = null;
      savedUser.e2e_comments = null;
    }

    savedUser.pioneerid = Number(savedUser.pioneerid);
    savedUser.language_group_id =
      savedUser.language_group_id === null || savedUser.language_group_id === HourglassGlobals.cong!.id
        ? null
        : Number(savedUser.language_group_id);
    const method = user.id ? "put" : "post";
    const headers = forceDecrypted ? FORCE_DECRYPT_HEADER.headers : undefined;

    const data = await api(url, { method, json: savedUser, headers }).json();
    return await UserAPI.userFromResponse(data);
  }

  async revoke(user: User): Promise<User> {
    const url = `${UserAPI.base}/revoke/${user.id}`;
    const data = await api.put(url).json();
    return await UserAPI.userFromResponse(data);
  }

  async unrevoke(user: User): Promise<User> {
    const url = `${UserAPI.base}/unrevoke/${user.id}`;
    const data = await api.put(url).json();
    return await UserAPI.userFromResponse(data);
  }

  async delete(user: User) {
    return api.delete(UserAPI.urlFromId(user.id));
  }

  async addDelegate(user: User, delegateUserId: number): Promise<User> {
    const url = `${UserAPI.base}/delegates`;
    const delegateRequest: Delegate = { id: 0, users_id: user.id, delegateuser_id: delegateUserId };
    const data = await api.post(url, { json: delegateRequest }).json<Delegate>();
    user.delegates ? user.delegates.push(data) : (user.delegates = [data]);
    return user;
  }

  async removeDelegate(user: User, delegateId: number): Promise<User> {
    await api.delete(`${UserAPI.base}/delegates/${delegateId}`);
    if (user.delegates) {
      user.delegates = user.delegates.filter((d) => d.id !== delegateId);
    }
    return user;
  }

  async updateMe(userUpdate: UserSelfUpdate) {
    await api.patch(`${UserAPI.base}/me`, { json: userUpdate });
  }
}

//a small custom hook to get the users
export function useUsers(options?: Partial<UseQueryOptions<User[], Error>>) {
  return useQuery({
    queryKey: [QueryKeys.Users],

    queryFn: () => {
      if (
        HourglassGlobals.permissions.has(Permission.ViewUsers) ||
        HourglassGlobals.permissions.has(Permission.ViewUsersMinimal)
      )
        return userApi.getAll();
      else return userApi.getAuthUser();
    },

    ...options,
  });
}

const userApi = new UserAPI();
export default userApi;
