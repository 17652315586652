import { Circuit } from "../types/circuit";
import User from "../types/user";
import { api, circuitPath } from "./api";

class CircuitAPI {
  static path = circuitPath("/");

  async getMyCircuitData(): Promise<Circuit[]> {
    const url = CircuitAPI.path;
    return api.get(url).json();
  }

  async getCircuitAdminsForMyCong(): Promise<Circuit> {
    const url = CircuitAPI.path + "cong";
    return api.get(url).json();
  }

  async setMyCircuit(code: string): Promise<number> {
    const url = CircuitAPI.path + "cong";
    return api.put(url, { json: { code: code } }).json();
  }

  async setMyCong(cong_id: number | null): Promise<void> {
    const url = CircuitAPI.path;
    return api.put(url, { json: { cong_id: cong_id } }).json();
  }

  async generate(circuit_id: number): Promise<Circuit[]> {
    const url = CircuitAPI.path + "gencode";
    return api.put(url, { json: { circuit_id: circuit_id } }).json();
  }

  async getCOUser(email: string): Promise<User> {
    const url = CircuitAPI.path + "usersearch";
    return api.post(url, { json: { email: email } }).json();
  }

  async removeCongFromCircuit(): Promise<void> {
    const url = CircuitAPI.path + "cong";
    return api.delete(url).json();
  }

  async deleteCircuitAdmin(circuit_id: number, user_id: number): Promise<void> {
    const url = CircuitAPI.path;
    return api.delete(url, { json: { circuit_id: circuit_id, user_id: user_id } }).json();
  }

  async addCircuitAdmin(circuit_id: number, user_id: number): Promise<void> {
    const url = CircuitAPI.path;
    return api.post(url, { json: { circuit_id: circuit_id, user_id: user_id } }).json();
  }

  async coReg(payload: string): Promise<void> {
    const url = CircuitAPI.path + "coreg";
    return api.post(url, { json: { msg: payload } }).json();
  }
}

export const circuitApi = new CircuitAPI();
