import { UseQueryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import HourglassGlobals from "../helpers/globals";
import { guidRegex } from "../helpers/util";
import { Cong, CongPatch, CongSearchResponse, CongUpdate, LanguageGroup } from "../types/cong";
import { JWorgCong } from "../types/jworg";
import { DuplicateMessage } from "../types/register/registerCong";
import { RegistrationModel } from "../types/register/registerModel";
import { CongSettings } from "../types/scheduling/settings";
import { api, fsReportPath, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";

class CongregationAPI {
  static base = fsReportPath("/congregation");

  static urlFromId(id: number): string {
    return `${CongregationAPI.base}/${id}`;
  }

  async delete(id: number) {
    await api.delete(CongregationAPI.urlFromId(id));
  }

  async unDelete(id: number) {
    const url = `${CongregationAPI.urlFromId(id)}/undelete`;
    await api.put(url);
  }

  async get(): Promise<Cong> {
    return api.get(CongregationAPI.base).json();
  }

  async update(cong: CongUpdate): Promise<Cong> {
    return api.put(CongregationAPI.urlFromId(cong.id), { json: cong }).json();
  }

  async getSettings(lgroup?: number): Promise<CongSettings> {
    const searchParams = lgroup ? { lgroup: lgroup } : undefined;
    return api.get(schedulingPath("/congregation/settings"), { searchParams }).json();
  }

  async setSettings(settings: CongSettings, lgroup: number): Promise<CongSettings> {
    const config = lgroup > 0 ? { searchParams: { lgroup: lgroup }, json: settings } : { json: settings };
    return api.put(schedulingPath("/congregation/settings"), config).json();
  }

  async registerRecaptcha(value: any, state: string) {
    const url = `${CongregationAPI.base}/recaptcha`;
    await api.post(url, { json: { response: value, state: state } });
  }

  async register(model: RegistrationModel) {
    await api.post(CongregationAPI.base, { json: model });
  }

  async registerDuplicateMessage(message: DuplicateMessage) {
    const url = `${CongregationAPI.base}/dupmessage`;
    await api.post(url, { json: message });
  }

  async addGuid(congId: number, shareText: string): Promise<Cong> {
    const congPatch: CongPatch = { congShareText: shareText };
    return api.patch(CongregationAPI.urlFromId(congId), { json: congPatch }).json();
  }

  async addLangGroup(shareText: string): Promise<Cong> {
    const congPatch: CongPatch = { congShareText: shareText };
    return api.post(fsReportPath("/congregation/lgroup"), { json: congPatch }).json();
  }

  async addUnlistedLangGroup(group: LanguageGroup): Promise<Cong> {
    return api.post(fsReportPath("/congregation/lgroup/unlisted"), { json: group }).json();
  }

  async setUnlistedLangGroup(group: LanguageGroup): Promise<Cong> {
    return api.put(fsReportPath(`/congregation/lgroup/unlisted/${group.id}`), { json: group }).json();
  }

  async getLangGroups(): Promise<Cong[]> {
    return api.get(fsReportPath("/congregation/lgroup")).json();
  }

  async deleteLangGroup(lgroup_id: number) {
    await api.delete(fsReportPath(`/congregation/lgroup/${lgroup_id}`));
  }

  async getCongInfoFromJWOrg(guid: string): Promise<JWorgCong> {
    if (getGUID(guid) === "") return { properties: { orgName: "..." } } as JWorgCong;
    return api.get(schedulingPath(`/congregation/jworg/${guid}`)).json();
  }

  // does not require auth, but highly rate limited
  async getCongInfoFromJWOrgPublic(guid: string): Promise<JWorgCong> {
    if (getGUID(guid) === "") return { properties: { orgName: "..." } } as JWorgCong;
    return api.get(schedulingPath(`/congregation/guid/${guid}`)).json();
  }

  async getBrazilUnlisted(congNumber: number): Promise<CongSearchResponse> {
    return api.get(schedulingPath(`/congregation/bru/${congNumber}`)).json();
  }
}

export const congregationApi = new CongregationAPI();

export function useCongSettings(
  lgroup = HourglassGlobals.cong!.id,
  options?: Partial<UseQueryOptions<CongSettings, Error>>,
) {
  return useQuery({
    ...options,
    staleTime: 15 * 60 * 1000, // 15 minutes, to avoid fetching this so frequently
    queryKey: [QueryKeys.CongregationSettings, lgroup],
    queryFn: () => congregationApi.getSettings(lgroup),
  });
}

export function useCongSettingsSuspense(lgroup = HourglassGlobals.cong!.id) {
  return useSuspenseQuery({
    queryKey: [QueryKeys.CongregationSettings, lgroup],
    queryFn: () => congregationApi.getSettings(lgroup),
  });
}

const getGUID = (inputText: string): string => {
  const result = guidRegex.exec(inputText);
  if (!result || result.length !== 1) return "";
  return result[0];
};
