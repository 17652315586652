import EmergencyContact from "./emergencycontact";
import { Tag } from "./scheduling/tags";

export enum UserGender {
  Male = "Male",
  Female = "Female",
}

type User = {
  id: number;
  uuid?: string;
  firstname: string;
  lastname: string;
  middlename?: string;
  displayName: string;
  email?: string;
  suffix?: string;
  descriptor?: string;
  anointed?: boolean;
  cellphone?: string;
  homephone?: string;
  otherphone?: string;
  address_id?: number;
  birth?: string;
  baptism?: string;
  sex?: UserGender;
  appt?: UserAppt;
  status?: UserStatus;
  rpquota?: number;
  group_id?: number;
  language_group_id: number | null;
  familycontact: boolean;
  piiconsentdate?: string;
  comments?: string;
  firstmonth?: string;
  reportstobranch: boolean;
  pioneerid?: number;
  loginemail?: string;
  googlesub?: string;
  locales_id?: number;
  mobilepush: boolean;
  permissionusergroups?: UserPermissionGroup[];
  sgo: boolean;
  revoked: boolean;
  lastmobiletoken?: string;
  pubinvite?: string;
  delegates?: Delegate[];
  emergencycontacts?: EmergencyContact[];
  googleauth: boolean;
  appleauth: boolean;
  tags: Tag[];
};

export function blankUser(): User {
  return {
    id: 0,
    sex: undefined,
    firstname: "",
    lastname: "",
    displayName: "",
    familycontact: false,
    reportstobranch: false,
    mobilepush: false,
    sgo: false,
    revoked: false,
    googleauth: false,
    appleauth: false,
    language_group_id: null,
    tags: [],
  };
}

export enum UserStatus {
  Baptized = "Baptized Publisher",
  ContinuousAuxiliary = "Continuous Auxiliary Pioneer",
  RegularPioneer = "Regular Pioneer",
  SpecialPioneer = "Special Pioneer",
  Unbaptized = "Unbaptized Publisher",
  StudentOnly = "StudentOnly",
  CO = "CO",
}

export enum UserAppt {
  Elder = "Elder",
  MS = "MS",
}

type UserPermissionGroup = {
  id: number;
  user_id: number;
  group_id: number;
};

export type Delegate = {
  id: number;
  users_id: number;
  delegateuser_id: number;
};

export type UserId = {
  id: number;
};

export type UserIds = {
  ids: number[];
};

export type UserParams = {
  id: string;
};

export type UserSelfUpdate = {
  localeId: number;
};

export default User;
